<app-bar-alert alerts-code="sl-alerts"></app-bar-alert>

<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-10">
        <p>
          Total shipments:
          {{ loadingShipmentsStats ? "Fetching..." : shipmentsStats.shipments }}
        </p>
      </div>

      <div class="col-sm-2">
        <button
          *ngIf="labelPrinterData?.allowManifest"
          class="btn"
          (click)="openManifestConfirmationModal()"
        >
          {{ loadingManifests ? "Loading" : "Create" }} manifest
        </button>
        <div class="col-sm-auto">
          <app-manifests-list></app-manifests-list>
        </div>
      </div>

      <div class="col-sm-12">
        <p>
          Total unprinted labels:
          {{
          loadingShipmentsStats
            ? "Fetching..."
            : shipmentsStats.unprintedShipments
          }}
        </p>
      </div>

      <div class="col-sm-12">
        <p>
          Shipments being printed: {{ totalShipmentsBeingPrinted }}
        </p>
      </div>
    </div>
    <div class="row">
      <ul class="col-sm-12 list-container">
        <li class="row list-item">
          <div class="col-sm-3">User's name</div>
          <div class="col-sm-2">User's address</div>

          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{
                'button-animation--active': downloadingPdfByTrackingCodes
              }"
              (click)="printByTrackingNumbers = true"
            >
              Print by tracking numbers
            </button>
          </div>
          <div class="col-sm-auto ml-auto">
            <button
              class="labelSearcherTrigger button-animation pointer"
              type="button"
              [ngClass]="{ 'button-animation--active': downloadingPdf }"
              (click)="showBuildsOptions = true"
            >
              Print build
            </button>
          </div>

          <div class="col-sm-auto">
            <app-builds-list></app-builds-list>
          </div>
        </li>

        <li
          class="row list-item"
          *ngFor="let shipment of filteredLabels(shipmentsLabelsRows)"
          [ngClass]="{ 'list-item--printed': shipment.printed }"
        >
          <div class="col-sm-3">{{ shipment.userName }}</div>
          <div class="col-sm-6">
            {{ (getShipmentAddress(shipment) | async)?.label }}
          </div>
          <div class="col-sm-auto ml-sm-auto">
            <button
              *ngIf="!shipment.refunded"
              class="list-item__button"
              (click)="onPrintLabel(shipment.shipmentId)"
            >
              Print label
            </button>
            <button
              *ngIf="shipment.refunded"
              style="cursor: not-allowed"
              class="list-item__button"
            >
              Label refunded
            </button>
          </div>
        </li>
      </ul>

      <div class="col-sm-12">
        <div class="loadMoreWrapper">
          <button
            class="loadMoreButton button-animation"
            [disabled]="loadingShipment"
            (click)="onLoadMore(page + 1)"
            [ngClass]="{ 'button-animation--active': loadingShipment }"
          >
            {{
            loadingShipment
              ? "Fetching more shipments"
              : "Load More shipments"
            }}
          </button>
        </div>
      </div>
    </div>
  </section>
</div>

<app-modal
  title="Printing options"
  [show]="showBuildsOptions"
  (atCloseBtn)="showBuildsOptions = false"
  secondaryBtn="Cancel"
  (atSecondaryBtn)="showBuildsOptions = false"
  primaryBtn="Print"
  [disablePrimaryBtn]="buildOptionsForm.invalid"
  (atPrimaryBtn)="onPrintBuild(buildOptionsForm.value)"
>
  <form
    [formGroup]="buildOptionsForm"
    novalidate
    (ngSubmit)="onPrintBuild($event.value)"
    class="row"
  >
    <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">1. Select Month:</label>
      <select
        formControlName="date"
        id="buildOptionsForm__date"
        class="form-control"
        *ngIf="shipmentsStats && shipmentsStats.monthlyStats"
      >
        <option value="ALL">All</option>
        <option *ngFor="let month of monthlyStatsList" [value]="month">
          {{ month }} (Available:{{ shipmentsStats.monthlyStats[month].total }},
          On Hold: {{ shipmentsStats.monthlyStats[month].onHold }})
        </option>
      </select>
      <small class="form-text text-muted"
      >Month when labels where created.</small
      >
    </div>

    <div class="form-group col-sm-12">
      <label>2. Select type:</label>

      <select name="option" id="" class="form-control" formControlName="option">
        <option value="ALL">
          ALL (Available: {{optionStats.ALL?.available }}, On Hold: {{optionStats?.ALL?.onHold}})
        </option>
        <option value="NEW">
          NEW (Available: {{optionStats.NEW?.available}}, On Hold: {{optionStats?.NEW?.onHold}})
        </option>
        <option value="NEW_KIWIPEARLS">
          NEW KIWIPEARLS (Available: {{optionStats.NEW_KIWIPEARLS?.available}}, On Hold: {{optionStats?.NEW_KIWIPEARLS?.onHold}})
        </option>
        <option value="CUSTOM_BOX">
          Custom Name (Available: {{optionStats.CUSTOM_BOX?.available}}, On Hold: {{optionStats.CUSTOM_BOX?.onHold}})
        </option>
        <option value="NEW_GIFT_GIFTIT">
          NEW GIFT / GIFTIT (Available: {{optionStats.NEW_GIFT_GIFTIT?.available}}, On
          Hold: {{optionStats.NEW_GIFT_GIFTIT?.onHold}})
        </option>
        <option value="NEW_GIFT_GIFTIT_KIWIPEARLS">
          NEW GIFT / GIFT IT KIWIPEARLS (Available: {{optionStats.NEW_GIFT_GIFTIT_KIWIPEARLS?.available}}, On Hold: {{optionStats?.NEW_GIFT_GIFTIT_KIWIPEARLS?.onHold}})
        </option>
        <!--        <option value="GIFT_WITH_PURCHASE">GIFT WITH PURCHASE (Available: {{optionStats?.available
        }}, On Hold: {{optionStats?.onHold}})</option>-->
        <option value="FUTURE_GIFT">
          FUTURE GIFT / GIFTIT (Available: {{optionStats.FUTURE_GIFT?.available}}, On
          Hold: {{optionStats.FUTURE_GIFT?.onHold}})
        </option>
        <option value="GIFT_COMPLETE">
          GIFT COMPLETE (Available: {{optionStats.GIFT_COMPLETE?.available}}, On
          Hold: {{optionStats.GIFT_COMPLETE?.onHold}})
        </option>
        <option value="SCHED">
          SCHED (Available: {{optionStats.SCHED?.available}}, On Hold: {{optionStats?.SCHED?.onHold}})
        </option>
        <option value="SCHED_GIFT">
          SCHED GIFT (Available: {{optionStats.SCHED_GIFT?.available}}, On Hold: {{optionStats?.SCHED_GIFT?.onHold}})
        </option>
        <option value="RE-SHIP">
          RE-SHIP (Available: {{optionStats.RE_SHIP?.available }}, On Hold: {{optionStats.RE_SHIP?.onHold }})
        </option>

        <option *ngIf="showALl || optionStats.PRIDE_BOX?.available" value="PRIDE_BOX">PRIDE BOX
          (Available: {{optionStats.PRIDE_BOX?.available}}, On Hold: {{optionStats.PRIDE_BOX?.onHold}})
        </option>

        // Dec OTBs 2020
        <option value="OTB_BUNNY_EAR" *ngIf="showALl || optionStats.OTB_BUNNY_EAR?.available">
          OTB Bunny Ear (Available: {{optionStats.OTB_BUNNY_EAR?.available}}, On
          Hold: {{optionStats.OTB_BUNNY_EAR?.onHold}})
        </option>

        // Feb OTBs 2021
        <option value="OTB_SILVER_DOLLAR" *ngIf="showALl || optionStats.OTB_SILVER_DOLLAR?.available">
          OTB Silver Dollar (Available: {{optionStats.OTB_SILVER_DOLLAR?.available}}, On
          Hold: {{optionStats.OTB_SILVER_DOLLAR?.onHold}})
        </option>

        // APR OTBs 2021
        <option value="THIMBLE_CACTUS" *ngIf="showALl || optionStats.THIMBLE_CACTUS?.available">
          OTB Thimble Cactus (Available: {{optionStats.THIMBLE_CACTUS?.available}}, On
          Hold: {{optionStats.THIMBLE_CACTUS?.onHold}})
        </option>

        // Jun OTBs 2021
        <option value="PICKLE_PLANT" *ngIf="showALl || optionStats.PICKLE_PLANT?.available">
          OTB Pickle Plant (Available: {{optionStats.PICKLE_PLANT?.available}}, On
          Hold: {{optionStats.PICKLE_PLANT?.onHold}})
        </option>

        // July OTBs 2021
        <option value="LEMON_CALICO" *ngIf="showALl || optionStats.LEMON_CALICO?.available">
          OTB Lemon Lime + Calico Kitten Box (Available: {{optionStats.LEMON_CALICO?.available}}, On
          Hold: {{optionStats.LEMON_CALICO?.onHold}})
        </option>

        // AUG OTB 2021
        <option value="OTB_ECHEVERIA" *ngIf="showALl || optionStats.OTB_ECHEVERIA?.available">
          OTB Painted Echeveria + Propeller Plant (Available: {{optionStats.OTB_ECHEVERIA?.available}}, On
          Hold: {{optionStats.OTB_ECHEVERIA?.onHold}})
        </option>

        <option value="OTB_LAVENDER_WHEAT" *ngIf="showALl || optionStats.OTB_LAVENDER_WHEAT?.available">
          OTB Lavender + Wheat Bundle Box (Available: {{optionStats.OTB_LAVENDER_WHEAT?.available}}, On
          Hold: {{optionStats.OTB_LAVENDER_WHEAT?.onHold}})
        </option>

        // Sep OTB 2021
        <option value="OTB_ALLIGATOR_PICKLE" *ngIf="showALl || optionStats.OTB_ALLIGATOR_PICKLE?.available">
          OTB Alligator Plant + Pickle Plant (Available: {{optionStats.OTB_ALLIGATOR_PICKLE?.available}}, On
          Hold: {{optionStats.OTB_ALLIGATOR_PICKLE?.onHold}})
        </option>

        <option value="OTB_GWP_HOUSE_PLANT" *ngIf="showALl || optionStats.OTB_GWP_HOUSE_PLANT?.available">
          OTB GWP House plant (Available: {{optionStats.OTB_GWP_HOUSE_PLANT?.available}}, On
          Hold: {{optionStats.OTB_GWP_HOUSE_PLANT?.onHold}})
        </option>

        <option value="OTB_PICKLE_THIMBLE" *ngIf="showALl || optionStats.OTB_PICKLE_THIMBLE?.available">
          OTB Thimble Cactus + Pickle Plant (Available: {{optionStats.OTB_PICKLE_THIMBLE?.available}}, On
          Hold: {{optionStats.OTB_PICKLE_THIMBLE?.onHold}})
        </option>

        <option value="OTB_SEPTEMBER_BOX" *ngIf="showALl || optionStats.OTB_SEPTEMBER_BOX?.available">
          OTB September box (Available: {{optionStats.OTB_SEPTEMBER_BOX?.available}}, On
          Hold: {{optionStats.OTB_SEPTEMBER_BOX?.onHold}})
        </option>

        // OCT OTB 2021
        <option value="OTB_FROSTY_GREY" *ngIf="showALl || optionStats.OTB_FROSTY_GREY?.available">
          OTB Echeveria Frosty + Echeveria Grey Red (Available: {{optionStats.OTB_FROSTY_GREY?.available}}, On
          Hold: {{optionStats.OTB_FROSTY_GREY?.onHold}})
        </option>

        <option value="OTB_FRED_PROLIFICA" *ngIf="showALl || optionStats.OTB_FRED_PROLIFICA?.available">
          OTB Fred Ives + Prolifica Box (Available: {{optionStats.OTB_FRED_PROLIFICA?.available}}, On
          Hold: {{optionStats.OTB_FRED_PROLIFICA?.onHold}})
        </option>

        <option value="OTB_AEONIUM_CONCRETE_POT" *ngIf="showALl || optionStats.OTB_AEONIUM_CONCRETE_POT?.available">
          OTB Aeonium Luteovariegatum + Concrete Pot (Available: {{optionStats.OTB_AEONIUM_CONCRETE_POT?.available}}, On
          Hold: {{optionStats.OTB_AEONIUM_CONCRETE_POT?.onHold}})
        </option>

        <option value="OTB_PILEA_MONEY" *ngIf="showALl || optionStats.OTB_PILEA_MONEY?.available">
          OTB Pilea Chinese Money Tree Box (Available: {{optionStats.OTB_PILEA_MONEY?.available}}, On
          Hold: {{optionStats.OTB_PILEA_MONEY?.onHold}})
        </option>

        <option value="OTB_OCT_BOX" *ngIf="showALl || optionStats.OTB_OCT_BOX?.available">
          OTB OCTOBER Box (Available: {{optionStats.OTB_OCT_BOX?.available}}, On
          Hold: {{optionStats.OTB_OCT_BOX?.onHold}})
        </option>

        <option value="OTB_PILEA_FRIENDSHIP_PLANT" *ngIf="showALl || optionStats.OTB_PILEA_FRIENDSHIP_PLANT?.available">
          OTB Pilea Friendship Plant (Available: {{optionStats.OTB_PILEA_FRIENDSHIP_PLANT?.available}}, On
          Hold: {{optionStats.OTB_PILEA_FRIENDSHIP_PLANT?.onHold}})
        </option>

        // NOV OTB 2021
        <option value="OTB_LOLA_ZEBRA" *ngIf="showALl || optionStats.OTB_LOLA_ZEBRA?.available">
          OTB Lola + Zebra Box (Available: {{optionStats.OTB_LOLA_ZEBRA?.available}}, On
          Hold: {{optionStats.OTB_LOLA_ZEBRA?.onHold}})
        </option>
        <option value="OTB_NOVEMBER_BOX" *ngIf="showALl || optionStats.OTB_NOVEMBER_BOX?.available">
          OTB November Box (Available: {{optionStats.OTB_NOVEMBER_BOX?.available}}, On
          Hold: {{optionStats.OTB_NOVEMBER_BOX?.onHold}})
        </option>
        <option value="OTB_ALOE_PINK_BLUSH" *ngIf="showALl || optionStats.OTB_ALOE_PINK_BLUSH?.available">
          OTB Aloe Pink Blush + Concrete Pot Box (Available: {{optionStats.OTB_ALOE_PINK_BLUSH?.available}}, On
          Hold: {{optionStats.OTB_ALOE_PINK_BLUSH?.onHold}}
          )
        </option>

        // OTB Free BOGO
        <option value="OTB_BOGO_FREE_BOX" *ngIf="showALl || optionStats.OTB_BOGO_FREE_BOX?.available">
          Free BOGO OTB Box (Available: {{optionStats.OTB_BOGO_FREE_BOX?.available}}, On
          Hold: {{optionStats.OTB_BOGO_FREE_BOX?.onHold}})
        </option>

        // DEC OTB 2021
        <option value="OTB_AUSSIE_PINE" *ngIf="showALl || optionStats.OTB_AUSSIE_PINE?.available">
          OTB Aussie Pine Bundle Box (Available: {{optionStats.OTB_AUSSIE_PINE?.available}}, On
          Hold: {{optionStats.OTB_AUSSIE_PINE?.onHold}})
        </option>

        <option value="OTB_DECEMBER_BOX" *ngIf="showALl || optionStats.OTB_DECEMBER_BOX?.available">
          OTB December Box (Available: {{optionStats.OTB_DECEMBER_BOX?.available}}, On
          Hold: {{optionStats.OTB_DECEMBER_BOX?.onHold}})
        </option>

        // JAN OTB 2022
        <option value="OTB_CORAL_CONCRETE" *ngIf="showALl || optionStats.OTB_CORAL_CONCRETE?.available">
          OTB Echeveria Coral Reef Aqua + Concrete Pot (Available: {{optionStats.OTB_CORAL_CONCRETE?.available}}, On
          Hold: {{optionStats.OTB_CORAL_CONCRETE?.onHold}})
        </option>

        <option value="OTB_2X_CORAL" *ngIf="showALl || optionStats.OTB_2X_CORAL?.available">
          OTB 2x Coral Reef Aqua Box (Available: {{optionStats.OTB_2X_CORAL?.available}}, On
          Hold: {{optionStats.OTB_2X_CORAL?.onHold}})
        </option>

        // JAN OTB 2022
        <option value="OTB_JAN_2022" *ngIf="showALl || optionStats.OTB_JAN_2022?.available">
          OTB January 2022 box (Available: {{optionStats.OTB_JAN_2022?.available}}, On
          Hold: {{optionStats.OTB_JAN_2022?.onHold}})
        </option>
        <option value="OTB_4_PACK" *ngIf="showALl || optionStats.OTB_4_PACK?.available">
          OTB 4 Pack box (Available: {{optionStats.OTB_4_PACK?.available}}, On Hold: {{optionStats.OTB_4_PACK?.onHold}})
        </option>

        // March OTB 2022
        <option value="OTB_FEB_BOX" *ngIf="showALl || optionStats.OTB_FEB_BOX?.available">
          OTB February 2022 box (Available: {{optionStats.OTB_FEB_BOX?.available}}, On
          Hold: {{optionStats.OTB_FEB_BOX?.onHold}})
        </option>
        <option value="OTB_MOONGLOW" *ngIf="showALl || optionStats.OTB_MOONGLOW?.available">
          OTB Moonglow box (Available: {{optionStats.OTB_MOONGLOW?.available}}, On
          Hold: {{optionStats.OTB_MOONGLOW?.onHold}})
        </option>

        <option value="OTB_MOONGLOW_LAVENDER_SCALLOP" *ngIf="showALl || optionStats.OTB_MOONGLOW_LAVENDER_SCALLOP?.available">
          OTB Moonglow + Lavender Scallops Box (Available: {{optionStats.OTB_MOONGLOW_LAVENDER_SCALLOP?.available}}, On
          Hold: {{optionStats.OTB_MOONGLOW_LAVENDER_SCALLOP?.onHold}})
        </option>

        // March OTB 2022
        <option value="OTB_PEARLS_BOX" *ngIf="showALl || optionStats.OTB_PEARLS_BOX?.available">
          OTB String of Pearls Box (Available: {{optionStats.OTB_PEARLS_BOX?.available}}, On
          Hold: {{optionStats.OTB_PEARLS_BOX?.onHold}})
        </option>
        <option value="OTB_DON_SOB" *ngIf="showALl || optionStats.OTB_DON_SOB?.available">
          OTB String of Buttons + Donkey's Tail box (Available: {{optionStats.OTB_DON_SOB?.available}}, On
          Hold: {{optionStats.OTB_DON_SOB?.onHold}})
        </option>
        <option value="OTB_ECHEVERIA_SENECIO" *ngIf="showALl || optionStats.OTB_ECHEVERIA_SENECIO?.available">
          OTB Echeveria Afterglow + Senecio Spear Head box: {{optionStats.OTB_ECHEVERIA_SENECIO?.available}}, On
          Hold: {{optionStats.OTB_ECHEVERIA_SENECIO?.onHold}})
        </option>
        <option value="OTB_CACTUS_DAY" *ngIf="showALl || optionStats.OTB_CACTUS_DAY?.available">
          OTB Cactus Day box: {{optionStats.OTB_CACTUS_DAY?.available}}, On
          Hold: {{optionStats.OTB_CACTUS_DAY?.onHold}})
        </option>

        // May 2022
        <option value="OTB_WILD_PUSSY_WILLOW" *ngIf="showALl || optionStats.OTB_WILD_PUSSY_WILLOW?.available">
          OTB Wild Pussy Willow Bundle Box: {{optionStats.OTB_WILD_PUSSY_WILLOW?.available}}, On
          Hold: {{optionStats.OTB_WILD_PUSSY_WILLOW?.onHold}})
        </option>

        // JUL 2022
        <option value="OTB_JUNE_BOX" *ngIf="showALl || optionStats.OTB_JUNE_BOX?.available">
          OTB JUNE BOX (Puliods + Mt. Everest): {{optionStats.OTB_JUNE_BOX?.available}}, On
          Hold: {{optionStats.OTB_JUNE_BOX?.onHold}})
        </option>
        <option value="OTB_PADDLE_POT_BOX" *ngIf="showALl || optionStats.OTB_PADDLE_POT_BOX?.available">
          OTB Paddle Plant + Pot Box: {{optionStats.OTB_PADDLE_POT_BOX?.available}}, On
          Hold: {{optionStats.OTB_PADDLE_POT_BOX?.onHold}})
        </option>

        // AUG 2022
        <option value="OTB_SPLIT_ROCK_BOX" *ngIf="showALl || optionStats.OTB_SPLIT_ROCK_BOX?.available">
          OTB Split Rock + Concrete Pot Box: {{optionStats.OTB_SPLIT_ROCK_BOX?.available}}, On
          Hold: {{optionStats.OTB_SPLIT_ROCK_BOX?.onHold}})
        </option>
        <option value="OTB_DOUBLE_RUBY" *ngIf="showALl || optionStats.OTB_DOUBLE_RUBY?.available">
          OTB Double Ruby Necklace Box: {{optionStats.OTB_DOUBLE_RUBY?.available}}, On
          Hold: {{optionStats.OTB_DOUBLE_RUBY?.onHold}})
        </option>
        <option value="OTB_LEAF_TOPSY_TURVY" *ngIf="showALl || optionStats.OTB_LEAF_TOPSY_TURVY?.available">
          OTB Concrete Leaf + Topsy Turvy Box: {{optionStats.OTB_LEAF_TOPSY_TURVY?.available}}, On
          Hold: {{optionStats.OTB_LEAF_TOPSY_TURVY?.onHold}})
        </option>
        <option value="OTB_CANDY_PLANT" *ngIf="showALl || optionStats.OTB_CANDY_PLANT?.available">
          OTB Cotton candy: {{optionStats.OTB_CANDY_PLANT?.available}}, On
          Hold: {{optionStats.OTB_CANDY_PLANT?.onHold}})
        </option>

<!--        Sep 2022-->
        <option value="OTB_AUG_PLANT" *ngIf="showALl || optionStats.OTB_AUG_PLANT?.available">
          OTB August box: {{optionStats.OTB_AUG_PLANT?.available}}, On
          Hold: {{optionStats.OTB_AUG_PLANT?.onHold}})
        </option>
        <option value="OTB_BELLE_DONKEY" *ngIf="showALl || optionStats.OTB_BELLE_DONKEY?.available">
          OTB Pachyveria 'Belle Blue' + Sedum 'Donkey's Tail': {{optionStats.OTB_BELLE_DONKEY?.available}}, On
          Hold: {{optionStats.OTB_BELLE_DONKEY?.onHold}})
        </option>
        <option value="OTB_PADDLE_DEDOS" *ngIf="showALl || optionStats.OTB_PADDLE_DEDOS?.available">
          OTB Kalanchoe 'Paddle Plant' + Sedum 'Dedos': {{optionStats.OTB_PADDLE_DEDOS?.available}}, On
          Hold: {{optionStats.OTB_PADDLE_DEDOS?.onHold}})
        </option>
        <option value="OTB_TACO_CARPET" *ngIf="showALl || optionStats.OTB_TACO_CARPET?.available">
          OTB Peperomia 'Taco Plant' + Crassula 'Small Red Carpet': {{optionStats.OTB_TACO_CARPET?.available}}, On
          Hold: {{optionStats.OTB_TACO_CARPET?.onHold}})
        </option>
        <option value="OTB_FANG_KIWI" *ngIf="showALl || optionStats.OTB_FANG_KIWI?.available">
          OTB Kalanchoe 'Fang' + Aeonium 'Kiwi': {{optionStats.OTB_FANG_KIWI?.available}}, On
          Hold: {{optionStats.OTB_FANG_KIWI?.onHold}})
        </option>
        <option value="OTB_PROLIFICA_JEWEL" *ngIf="showALl || optionStats.OTB_PROLIFICA_JEWEL?.available">
          OTB Echeveria 'Prolifica' + Pachyphytum 'Little Jewel': {{optionStats.OTB_PROLIFICA_JEWEL?.available}}, On
          Hold: {{optionStats.OTB_PROLIFICA_JEWEL?.onHold}})
        </option>
        <option value="OTB_SPOONS_DARLEY" *ngIf="showALl || optionStats.OTB_SPOONS_DARLEY?.available">
          OTB Kalanchoe 'Copper Spoons' + Graptosedum 'Darley Sunshine': {{optionStats.OTB_SPOONS_DARLEY?.available}}, On
          Hold: {{optionStats.OTB_SPOONS_DARLEY?.onHold}})
        </option>

<!--        October 2022-->
        <option value="OTB_SEP_BOX" *ngIf="showALl || optionStats.OTB_SEP_BOX?.available">
          OTB SEPT Box: {{optionStats.OTB_SEP_BOX?.available}}, On
          Hold: {{optionStats.OTB_SEP_BOX?.onHold}})
        </option>
        <option value="OTB_SUCSTU_BUNDLE" *ngIf="showALl || optionStats.OTB_SUCSTU_BUNDLE?.available">
          OTB Succulent Bundle Box: {{optionStats.OTB_SUCSTU_BUNDLE?.available}}, On
          Hold: {{optionStats.OTB_SUCSTU_BUNDLE?.onHold}})
        </option>

<!--        November 2022-->
        <option value="OTB_OCT22_BOX" *ngIf="showALl || optionStats.OTB_OCT22_BOX?.available">
          OTB The OCTOBER Box: {{optionStats.OTB_OCT22_BOX?.available}}, On
          Hold: {{optionStats.OTB_OCT22_BOX?.onHold}})
        </option>
        <option value="OTB_HURRICANE_PLANT" *ngIf="showALl || optionStats.OTB_HURRICANE_PLANT?.available">
          OTB The Hurricane Cactus Box: {{optionStats.OTB_HURRICANE_PLANT?.available}}, On
          Hold: {{optionStats.OTB_HURRICANE_PLANT?.onHold}})
        </option>

        <!--        December 2022-->
        <option value="OTB_NOV22_BOX" *ngIf="showALl || optionStats.OTB_NOV22_BOX?.available">
          OTB November box Box: {{optionStats.OTB_NOV22_BOX?.available}}, On
          Hold: {{optionStats.OTB_NOV22_BOX?.onHold}})
        </option>
        <option value="OTB_GIFT_BOX" *ngIf="showALl || optionStats.OTB_GIFT_BOX?.available">
          OTB Gift Box Bundle: {{optionStats.OTB_GIFT_BOX?.available}}, On
          Hold: {{optionStats.OTB_GIFT_BOX?.onHold}})
        </option>

        <!--        January 2023-->
        <option value="OTB_DEC22_BOX" *ngIf="showALl || optionStats.OTB_DEC22_BOX?.available">
          OTB December box: {{optionStats.OTB_DEC22_BOX?.available}}, On
          Hold: {{optionStats.OTB_DEC22_BOX?.onHold}})
        </option>


        <option value="OTB_WINTER1" *ngIf="showALl || optionStats.OTB_WINTER1?.available">
          OTB Echeveria Imbricata 'Blue Rose' + Aeomium 'Lily Pad': {{optionStats.OTB_WINTER1?.available}}, On
          Hold: {{optionStats.OTB_WINTER1?.onHold}})
        </option>
        <option value="OTB_WINTER2" *ngIf="showALl || optionStats.OTB_WINTER2?.available">
          OTB Sedum 'Donkey's Tail' + Echeveria 'Chroma': {{optionStats.OTB_WINTER2?.available}}, On
          Hold: {{optionStats.OTB_WINTER2?.onHold}})
        </option>
        <option value="OTB_WINTER3" *ngIf="showALl || optionStats.OTB_WINTER3?.available">
          OTB Gasteria Biocolor 'Lawyer's Tongue' + Sempervivum 'Cobweb Houseleek': {{optionStats.OTB_WINTER3?.available}}, On
          Hold: {{optionStats.OTB_WINTER3?.onHold}})
        </option>
        <option value="OTB_WINTER4" *ngIf="showALl || optionStats.OTB_WINTER4?.available">
          OTB Adromischus Cristatus 'Crinkle Leaf' + Anacampseros Rufescens 'Love Plant': {{optionStats.OTB_WINTER4?.available}}, On
          Hold: {{optionStats.OTB_WINTER4?.onHold}})
        </option>
        <option value="OTB_WINTER5" *ngIf="showALl || optionStats.OTB_WINTER5?.available">
          OTB Senico 'Mount Everest' + Crassula Mesembryanthemoides 'Fuzzy Crassula': {{optionStats.OTB_WINTER5?.available}}, On
          Hold: {{optionStats.OTB_WINTER5?.onHold}})
        </option>
        <option value="OTB_WINTER6" *ngIf="showALl || optionStats.OTB_WINTER6?.available">
          OTB Titanopsis Calcarea ‘Concrete-Leaf’ + Delosperma echinatum ‘Pickle Plant’: {{optionStats.OTB_WINTER6?.available}}, On
          Hold: {{optionStats.OTB_WINTER6?.onHold}})
        </option>

        <!--        January 2023-->
        <option value="OTB_JAN23_BOX" *ngIf="showALl || optionStats.OTB_JAN23_BOX?.available">
          OTB January box: {{optionStats.OTB_JAN23_BOX?.available}}, On
          Hold: {{optionStats.OTB_JAN23_BOX?.onHold}})
        </option>
        <option value="OTB_TIGER_ALOE" *ngIf="showALl || optionStats.OTB_TIGER_ALOE?.available">
          OTB Tiger Tooth Aloe Bundle Box: {{optionStats.OTB_TIGER_ALOE?.available}}, On
          Hold: {{optionStats.OTB_TIGER_ALOE?.onHold}})
        </option>

        <!--        February 2023-->
        <option value="OTB_2_BANANA" *ngIf="showALl || optionStats.OTB_2_BANANA?.available">
          2 Banana box: {{optionStats.OTB_2_BANANA?.available}}, On
          Hold: {{optionStats.OTB_2_BANANA?.onHold}})
        </option>
        <option value="OTB_TIGER_ALOE_2" *ngIf="showALl || optionStats.OTB_TIGER_ALOE_2?.available">
          OTB Tiger Tooth Aloe Bundle Box (missing): {{optionStats.OTB_TIGER_ALOE_2?.available}}, On
          Hold: {{optionStats.OTB_TIGER_ALOE_2?.onHold}})
        </option>

<!--        March 2023-->
        <option value="MARCH_4PACK" *ngIf="showALl || optionStats.MARCH_4PACK?.available">
          OTB Succulent 4 pack March 2023: {{optionStats.MARCH_4PACK?.available}}, On
          Hold: {{optionStats.MARCH_4PACK?.onHold}})
        </option>

<!--        April 2023-->
        <option value="DOUBLE_COPPERTONE" *ngIf="showALl || optionStats.DOUBLE_COPPERTONE?.available">
          OTB Double Coppertone Box: {{optionStats.DOUBLE_COPPERTONE?.available}}, On
          Hold: {{optionStats.DOUBLE_COPPERTONE?.onHold}})
        </option>

<!--        MAY 2023-->
        <option value="PANDA_COBWEB" *ngIf="showALl || optionStats.PANDA_COBWEB?.available">
          OTB Panda / Cobweb Box: {{optionStats.PANDA_COBWEB?.available}}, On
          Hold: {{optionStats.PANDA_COBWEB?.onHold}})
        </option>

<!--        July 2023-->
        <option value="OTB_MINIMA_DRAGON" *ngIf="showALl || optionStats.OTB_MINIMA_DRAGON?.available">
          The Minima + Dragon Box: {{optionStats.OTB_MINIMA_DRAGON?.available}}, On
          Hold: {{optionStats.OTB_MINIMA_DRAGON?.onHold}})
        </option>

        <!--        Aug 2023-->
        <option value="OTB_AUG2023" *ngIf="showALl || optionStats.OTB_AUG2023?.available">
          The AUGUST Box: {{optionStats.OTB_AUG2023?.available}}, On
          Hold: {{optionStats.OTB_AUG2023?.onHold}})
        </option>
        <option value="OTB_JUL2023" *ngIf="showALl || optionStats.OTB_JUL2023?.available">
          The JULY Box: {{optionStats.OTB_JUL2023?.available}}, On
          Hold: {{optionStats.OTB_JUL2023?.onHold}})
        </option>
        <option value="OTB_PERLE" *ngIf="showALl || optionStats.OTB_PERLE?.available">
          OTB Echeveria Perle Von Nuremberg Box: {{optionStats.OTB_PERLE?.available}}, On
          Hold: {{optionStats.OTB_PERLE?.onHold}})
        </option>

        <!--        Sep 2023-->
        <option value="OTB_SEP2023" *ngIf="showALl || optionStats.OTB_SEP2023?.available">
          OTB September 2023 Box: {{optionStats.OTB_SEP2023?.available}}, On
          Hold: {{optionStats.OTB_SEP2023?.onHold}})
        </option>
        <option value="OTB_TRICLR" *ngIf="showALl || optionStats.OTB_TRICLR?.available">
          OTB The Rhoeo Tricolor Box: {{optionStats.OTB_TRICLR?.available}}, On
          Hold: {{optionStats.OTB_TRICLR?.onHold}})
        </option>

        <!--        October 2023-->
        <option value="OTB_OCT2023" *ngIf="showALl || optionStats.OTB_OCT2023?.available">
          OTB October 2023 Box: {{optionStats.OTB_OCT2023?.available}}, On
          Hold: {{optionStats.OTB_OCT2023?.onHold}})
        </option>

        <!--        November 2023-->
        <option value="OTB_NOV2023" *ngIf="showALl || optionStats.OTB_NOV2023?.available">
          OTB November 2023 Box: {{optionStats.OTB_NOV2023?.available}}, On
          Hold: {{optionStats.OTB_NOV2023?.onHold}})
        </option>

        <!--        December 2023-->
        <option value="OTB_DEC2023" *ngIf="showALl || optionStats.OTB_DEC2023?.available">
          OTB December 2023 Box: {{optionStats.OTB_DEC2023?.available}}, On
          Hold: {{optionStats.OTB_DEC2023?.onHold}})
        </option>

        <option value="OTB_STRING_PEARLS_MINIMA" *ngIf="showALl || optionStats.OTB_STRING_PEARLS_MINIMA?.available">
          OTB SOP + Minima Box: {{optionStats.OTB_STRING_PEARLS_MINIMA?.available}}, On
          Hold: {{optionStats.OTB_STRING_PEARLS_MINIMA?.onHold}})
        </option>

        <!--        Jan 2024-->
        <option value="OTB_JAN2024" *ngIf="showALl || optionStats.OTB_JAN2024?.available">
          OTB January 2024 Box: {{optionStats.OTB_JAN2024?.available}}, On
          Hold: {{optionStats.OTB_JAN2024?.onHold}})
        </option>
        <option value="OTB_JAN2401" *ngIf="showALl || optionStats.OTB_JAN2401?.available">
          OTB 3 Pack Box: {{optionStats.OTB_JAN2401?.available}}, On
          Hold: {{optionStats.OTB_JAN2401?.onHold}})
        </option>

        <!--        Feb 2024-->
        <option value="OTB_FEB2024" *ngIf="showALl || optionStats.OTB_FEB2024?.available">
          OTB Feb 2024 Box: {{optionStats.OTB_FEB2024?.available}}, On
          Hold: {{optionStats.OTB_FEB2024?.onHold}})
        </option>

        <!--        Feb 2024-->
        <option value="OTB_MAR2024" *ngIf="showALl || optionStats.OTB_MAR2024?.available">
          OTB Mar 2024 Box: {{optionStats.OTB_MAR2024?.available}}, On
          Hold: {{optionStats.OTB_MAR2024?.onHold}})
        </option>

        // Multiple boxes landing
        <!--        <option value="GWP_AIR_PLANT">GWP: Air Plant (Available: {{optionStats?.available
        }}, On Hold: {{optionStats?.onHold}})</option>-->
        <!--        <option value="GWP_BB_PLANT">GWP: Baby Blue plant (Available: {{optionStats?.available
        }}, On Hold: {{optionStats?.onHold}})</option>-->
        <!--        <option value="GWP_FR_PLANT">GWP: Hummel's Sunset (Available: {{optionStats?.available
        }}, On Hold: {{optionStats?.onHold}})</option>-->
        <option value="GWP_CAC_PIN" *ngIf="showALl || optionStats.GWP_CAC_PIN?.available">
          GWP: Enamel cactus pin (Available: {{optionStats.GWP_CAC_PIN?.available}}, On
          Hold: {{optionStats.GWP_CAC_PIN?.onHold}})
        </option>
        <option value="GWP_DUSTYP" *ngIf="showALl || optionStats.GWP_DUSTYP?.available">
          GWP: Dusty Rose plant (Available: {{optionStats.GWP_DUSTYP?.available}}, On
          Hold: {{optionStats.GWP_DUSTYP?.onHold}})
        </option>
        <option value="GWP_RCARPT" *ngIf="showALl || optionStats.GWP_RCARPT?.available">
          GWP: Little Jewel (Available: {{optionStats.GWP_RCARPT?.available}}, On
          Hold: {{optionStats.GWP_RCARPT?.onHold}})
        </option>
        <option value="GWP_CACTUSCAT" *ngIf="showALl || optionStats.GWP_CACTUSCAT?.available">
          GWP: Cat Cactus Pin (Available: {{optionStats.GWP_CACTUSCAT?.available}}, On
          Hold: {{optionStats.GWP_CACTUSCAT?.onHold}})
        </option>
        <option value="GWP_3GRCAD" *ngIf="showALl || optionStats.GWP_3GRCAD?.available">
          GWP: 3-pack greeting cards (Available: {{optionStats.GWP_3GRCAD?.available}}, On
          Hold: {{optionStats.GWP_3GRCAD?.onHold}})
        </option>
        <option value="GWP_DECGFS" *ngIf="showALl || optionStats.GWP_DECGFS?.available">
          GWP: DECGIFTS (Available: {{optionStats.GWP_DECGFS?.available}}, On Hold: {{optionStats.GWP_DECGFS?.onHold}})
        </option>
        <option value="GWP_4PLANTS" *ngIf="showALl || optionStats.GWP_4PLANTS?.available">
          GWP: 4PLANTS (Available: {{optionStats.GWP_4PLANTS?.available
          }}, On Hold: {{optionStats.GWP_4PLANTS?.onHold}})
        </option>
        <option value="GWP_TCOSTK" *ngIf="showALl || optionStats.GWP_TCOSTK?.available">
          GWP TACOSTICKER (Available: {{optionStats.GWP_TCOSTK?.available
          }}, On Hold: {{optionStats.GWP_TCOSTK?.onHold}})
        </option>
        <option value="GWP_PINCAT" *ngIf="showALl || optionStats.GWP_PINCAT?.available">
          GWP Black Cat Pin (Available: {{optionStats.GWP_PINCAT?.available
          }}, On Hold: {{optionStats.GWP_PINCAT?.onHold}})
        </option>
        <option value="GWP_FEBGFT" *ngIf="showALl || optionStats.GWP_FEBGFT?.available">
          GWP Black Prince (Available: {{optionStats.GWP_FEBGFT?.available
          }}, On Hold: {{optionStats.GWP_FEBGFT?.onHold}})
        </option>
        <option value="GWP_MGOALS" *ngIf="showALl || optionStats.GWP_MGOALS?.available">
          GWP MARCHGOALS (Available: {{optionStats.GWP_MGOALS?.available }}, On Hold: {{optionStats.GWP_MGOALS?.onHold}})
        </option>
        <option value="GWP_APRGFT" *ngIf="showALl || optionStats.GWP_APRGFT?.available">
          GWP APRIL GIFT (Available: {{optionStats.GWP_APRGFT?.available }}, On Hold: {{optionStats.GWP_APRGFT?.onHold}})
        </option>
        <option value="GWP_TIGER_TOOTH" *ngIf="showALl || optionStats.GWP_TIGER_TOOTH?.available">
          GWP Tiger Tooth (Available: {{optionStats.GWP_TIGER_TOOTH?.available
          }}, On Hold: {{optionStats.GWP_TIGER_TOOTH?.onHold}})
        </option>
        <option value="GWP_MAYPIN00" *ngIf="showALl || optionStats.GWP_MAYPIN00?.available">
          GWP May cactus PIN (Available: {{optionStats.GWP_MAYPIN00?.available
          }}, On Hold: {{optionStats.GWP_MAYPIN00?.onHold}})
        </option>
        <option value="GWP_JUNETHREE" *ngIf="showALl || optionStats.GWP_JUNETHREE?.available">
          GWP JUNE PLANT (Available: {{optionStats.GWP_JUNETHREE?.available
          }}, On Hold: {{optionStats.GWP_JUNETHREE?.onHold}})
        </option>
        <option value="GWP_HALFJUNE" *ngIf="showALl || optionStats.GWP_HALFJUNE?.available">
          GWP Frosty (Available: {{optionStats.GWP_HALFJUNE?.available
          }}, On Hold: {{optionStats.GWP_HALFJUNE?.onHold}})
        </option>
        <option value="GWP_HALFJULY" *ngIf="showALl || optionStats.GWP_HALFJULY?.available">
          GWP Lemon & Lime (Available: {{optionStats.GWP_HALFJULY?.available
          }}, On Hold: {{optionStats.GWP_HALFJULY?.onHold}})
        </option>
        <option value="GWP_WETPLNT" *ngIf="showALl || optionStats.GWP_WETPLNT?.available">
          GWP WETPLANTS: Free Shipping + Free IWMP Pin (Available: {{optionStats.GWP_WETPLNT?.available
          }}, On Hold: {{optionStats.GWP_WETPLNT?.onHold}})
        </option>
        <option value="GWP_FALLIN" *ngIf="showALl || optionStats.GWP_FALLIN?.available">
          GWP Free Shipping + Lily Pad (Available: {{optionStats.GWP_FALLIN?.available
          }}, On Hold: {{optionStats.GWP_FALLIN?.onHold}})
        </option>
        <option value="GWP_BLCKIN" *ngIf="showALl || optionStats.GWP_BLCKIN?.available">
          GWP Free shipping + black cat pin (Available: {{optionStats.GWP_BLCKIN?.available
          }}, On Hold: {{optionStats.GWP_BLCKIN?.onHold}})
        </option>
        <option value="GWP_GIFNOV" *ngIf="showALl || optionStats.GWP_GIFNOV?.available">
          GWP Topsy Turvy (Available: {{optionStats.GWP_GIFNOV?.available
          }}, On Hold: {{optionStats.GWP_GIFNOV?.onHold}})
        </option>
        <option value="GWP_SPOTTED" *ngIf="showALl || optionStats.GWP_SPOTTED?.available">
          GWP Love plant, matchbox, free shipping (Available: {{optionStats.GWP_SPOTTED?.available
          }}, On Hold: {{optionStats.GWP_SPOTTED?.onHold}})
        </option>
        <option value="GWP_3PLANTS" *ngIf="showALl || optionStats.GWP_3PLANTS?.available">
          GWP Little Jewel (Available: {{optionStats.GWP_3PLANTS?.available
          }}, On Hold: {{optionStats.GWP_3PLANTS?.onHold}})
        </option>
        <option value="GWP_FUZZY" *ngIf="showALl || optionStats.GWP_FUZZY?.available">
          GWP GIFT NOTE CARDS (Available: {{optionStats.GWP_FUZZY?.available
          }}, On Hold: {{optionStats.GWP_FUZZY?.onHold}})
        </option>
        <option value="GWP_NOV220" *ngIf="showALl || optionStats.GWP_NOV220?.available">
          GWP cat pot pin (Available: {{optionStats.GWP_NOV220?.available
          }}, On Hold: {{optionStats.GWP_NOV220?.onHold}})
        </option>
        <option value="GWP_DEC022" *ngIf="showALl || optionStats.GWP_DEC022?.available">
          GWP Pollux + Note Cards (Available: {{optionStats.GWP_DEC022?.available
          }}, On Hold: {{optionStats.GWP_DEC022?.onHold}})
        </option>

        <option value="GWP_CACHOL" *ngIf="showALl || optionStats.GWP_CACHOL?.available">
          GWP Cactus Media Holiday (Available: {{optionStats.GWP_CACHOL?.available
          }}, On Hold: {{optionStats.GWP_CACHOL?.onHold}})
        </option>

        <option value="GWP_BUDDHA" *ngIf="showALl || optionStats.GWP_BUDDHA?.available">
          GWP Chroma + Crinkle-leaf (Available: {{optionStats.GWP_BUDDHA?.available
          }}, On Hold: {{optionStats.GWP_BUDDHA?.onHold}})
        </option>

        <option value="GWP_MVRCTU" *ngIf="showALl || optionStats.GWP_MVRCTU?.available">
          GWP Maverick Cactus (Available: {{optionStats.GWP_MVRCTU?.available
          }}, On Hold: {{optionStats.GWP_MVRCTU?.onHold}})
        </option>

        <option value="GWP_FRTPIN" *ngIf="showALl || optionStats.GWP_FRTPIN?.available">
          GWP Frosty / IWMP Pin (Available: {{optionStats.GWP_FRTPIN?.available
          }}, On Hold: {{optionStats.GWP_FRTPIN?.onHold}})
        </option>

        <option value="GWP_FPANDA" *ngIf="showALl || optionStats.GWP_FPANDA?.available">
          GWP Panda Plant (Available: {{optionStats.GWP_FPANDA?.available
          }}, On Hold: {{optionStats.GWP_FPANDA?.onHold}})
        </option>

        <option value="GWP_BRONZE" *ngIf="showALl || optionStats.GWP_BRONZE?.available">
          GWP Bronze (Available: {{optionStats.GWP_BRONZE?.available
          }}, On Hold: {{optionStats.GWP_BRONZE?.onHold}})
        </option>

        <option value="GWP_CAVDAY" *ngIf="showALl || optionStats.GWP_CAVDAY?.available">
          GWP Cactus media CACTUSVDAY (Available: {{optionStats.GWP_CAVDAY?.available
          }}, On Hold: {{optionStats.GWP_CAVDAY?.onHold}})
        </option>

        <option value="GWP_PLTLVE" *ngIf="showALl || optionStats.GWP_PLTLVE?.available">
          GWP Plant love (Available: {{optionStats.GWP_PLTLVE?.available
          }}, On Hold: {{optionStats.GWP_PLTLVE?.onHold}})
        </option>

        <option value="GWP_MRCHWS" *ngIf="showALl || optionStats.GWP_MRCHWS?.available">
          GWP Buddah (Available: {{optionStats.GWP_MRCHWS?.available
          }}, On Hold: {{optionStats.GWP_MRCHWS?.onHold}})
        </option>

        <option value="GWP_APRSPR" *ngIf="showALl || optionStats.GWP_APRSPR?.available">
          GWP APRIL SURPRISE (Available: {{optionStats.GWP_APRSPR?.available
          }}, On Hold: {{optionStats.GWP_APRSPR?.onHold}})
        </option>

        <option value="GWP_IWMPAS" *ngIf="showALl || optionStats.GWP_IWMPAS?.available">
          GWP IWMP air freshener (Available: {{optionStats.GWP_IWMPAS?.available
          }}, On Hold: {{optionStats.GWP_IWMPAS?.onHold}})
        </option>

        <option value="GWP_PANDA23" *ngIf="showALl || optionStats.GWP_PANDA23?.available">
          GWP Panda23 (Available: {{optionStats.GWP_PANDA23?.available
          }}, On Hold: {{optionStats.GWP_PANDA23?.onHold}})
        </option>

        <option value="GWP_BLUE23" *ngIf="showALl || optionStats.GWP_BLUE23?.available">
          GWP Blue Rose (Available: {{optionStats.GWP_BLUE23?.available
          }}, On Hold: {{optionStats.GWP_BLUE23?.onHold}})
        </option>

        <option value="GWP_MEMO23" *ngIf="showALl || optionStats.GWP_MEMO23?.available">
          Memorial GWP (Available: {{optionStats.GWP_MEMO23?.available
          }}, On Hold: {{optionStats.GWP_MEMO23?.onHold}})
        </option>

        <option value="GWP_0JUNE3" *ngIf="showALl || optionStats.GWP_0JUNE3?.available">
          GWP Cobweb (Available: {{optionStats.GWP_0JUNE3?.available
          }}, On Hold: {{optionStats.GWP_0JUNE3?.onHold}})
        </option>

        <option value="GWP_ZEBRAP" *ngIf="showALl || optionStats.GWP_ZEBRAP?.available">
          GWP ZEBRA (Available: {{optionStats.GWP_ZEBRAP?.available
          }}, On Hold: {{optionStats.GWP_ZEBRAP?.onHold}})
        </option>

        <option value="GWP_CRFRSH" *ngIf="showALl || optionStats.GWP_CRFRSH?.available">
          GWP 3-pack fresheners (Available: {{optionStats.GWP_CRFRSH?.available
          }}, On Hold: {{optionStats.GWP_CRFRSH?.onHold}})
        </option>

        <option value="GWP_RAINBW" *ngIf="showALl || optionStats.GWP_RAINBW?.available">
          GWP Rainbow Bush (Available: {{optionStats.GWP_RAINBW?.available
          }}, On Hold: {{optionStats.GWP_RAINBW?.onHold}})
        </option>

        <option value="GWP_BUDTMP" *ngIf="showALl || optionStats.GWP_BUDTMP?.available">
          GWP Buddha's Temple (Available: {{optionStats.GWP_BUDTMP?.available
          }}, On Hold: {{optionStats.GWP_BUDTMP?.onHold}})
        </option>

        <option value="GWP_BLCAPI" *ngIf="showALl || optionStats.GWP_BLCAPI?.available">
          GWP Black Cat Pin (Available: {{optionStats.GWP_BLCAPI?.available
          }}, On Hold: {{optionStats.GWP_BLCAPI?.onHold}})
        </option>

        <option value="GWP_GFTS23" *ngIf="showALl || optionStats.GWP_GFTS23?.available">
          GWP Cactus pin + Dragon plant (Available: {{optionStats.GWP_GFTS23?.available
          }}, On Hold: {{optionStats.GWP_GFTS23?.onHold}})
        </option>

        <option value="GWP_DCFRSH" *ngIf="showALl || optionStats.GWP_DCFRSH?.available">
          GWP one freshener (Available: {{optionStats.GWP_DCFRSH?.available
          }}, On Hold: {{optionStats.GWP_DCFRSH?.onHold}})
        </option>

        <option value="GWP_JANGREEN" *ngIf="showALl || optionStats.GWP_JANGREEN?.available">
          GWP Campfire (Available: {{optionStats.GWP_JANGREEN?.available
          }}, On Hold: {{optionStats.GWP_JANGREEN?.onHold}})
        </option>

        <option value="GWP_FREELAVENDER" *ngIf="showALl || optionStats.GWP_FREELAVENDER?.available">
          GWP Lavender Scallops: {{optionStats.GWP_FREELAVENDER?.available
          }}, On Hold: {{optionStats.GWP_FREELAVENDER?.onHold}})
        </option>

        <option value="GWP_BUDDHAPLEASE" *ngIf="showALl || optionStats.GWP_BUDDHAPLEASE?.available">
          GWP March Buddha: {{optionStats.GWP_BUDDHAPLEASE?.available
          }}, On Hold: {{optionStats.GWP_BUDDHAPLEASE?.onHold}})
        </option>

        <option value="GWP_GASTERIA" *ngIf="showALl || optionStats.GWP_GASTERIA?.available">
          GWP Gasteria Plant: {{optionStats.GWP_GASTERIA?.available
          }}, On Hold: {{optionStats.GWP_GASTERIA?.onHold}})
        </option>

        <option value="GWP_JULYGIFTS" *ngIf="showALl || optionStats.GWP_JULYGIFTS?.available">
          GWP JULYGIFTS: {{optionStats.GWP_JULYGIFTS?.available
          }}, On Hold: {{optionStats.GWP_JULYGIFTS?.onHold}})
        </option>

        <option value="DEF_DARLEY" *ngIf="showALl || optionStats.DEF_DARLEY?.available">
          Dec: Darley Sunshine (Available: {{optionStats.DEF_DARLEY?.available
          }}, On Hold: {{optionStats.DEF_DARLEY?.onHold}})
        </option>
        <option value="DEF_BEBLUE" *ngIf="showALl || optionStats.DEF_BEBLUE?.available">
          Dec: Belle Blue (Available: {{optionStats.DEF_BEBLUE?.available
          }}, On Hold: {{optionStats.DEF_BEBLUE?.onHold}})
        </option>
        <option value="DEF_LAALOE" *ngIf="showALl || optionStats.DEF_LAALOE?.available">
          Dec: Lace Aloe (Available: {{optionStats.DEF_LAALOE?.available
          }}, On Hold: {{optionStats.DEF_LAALOE?.onHold}})
        </option>
        <option value="DEF_DONKEY" *ngIf="showALl || optionStats.DEF_DONKEY?.available">
          Dec: Donkey's Tail (Available: {{optionStats.DEF_DONKEY?.available
          }}, On Hold: {{optionStats.DEF_DONKEY?.onHold}})
        </option>




<!--        cactus media -->
        <option value="MOTHERPLANT_CACTUSMEDIA" *ngIf="showALl || optionStats.MOTHERPLANT_CACTUSMEDIA?.available">
          Motherplant code from cactus media (Available: {{optionStats.MOTHERPLANT_CACTUSMEDIA?.available
          }}, On Hold: {{optionStats.MOTHERPLANT_CACTUSMEDIA?.onHold}})
        </option>
        <option value="GWP_CACTUSGF" *ngIf="showALl || optionStats.GWP_CACTUSGF?.available">
          GWP Cactus Media Free Plant (Available: {{optionStats.GWP_CACTUSGF?.available
          }}, On Hold: {{optionStats.GWP_CACTUSGF?.onHold}})
        </option>



      </select>
      <div class="form-group form-check text-right">
        <input type="checkbox" class="form-check-input" id="showAllOptions" [(ngModel)]="showALl"
               [ngModelOptions]="{standalone: true}">
        <label class="form-check-label" for="showAllOptions">Show All Options.</label>
      </div>
    </div>

    <!--<div class="form-group col-sm-12">
      <div><label>3. Select Carrier:</label></div>
      <div
        *ngFor="
          let rateObject of labelPrinterData?.defaultBuildRates;
          let i = index
        "
        class="form-check form-check-inline"
      >
        <input
          class="form-check-input"
          type="radio"
          id="carrierOption{{ i }}"
          formControlName="selectedCarrier"
          [value]="rateObject.carrier"
        />
        <label class="form-check-label" for="carrierOption{{ i }}">{{
          rateObject.name
          }}</label>
      </div>
    </div>-->

    <div class="form-group col-sm-12">
      <label for="buildOptionsForm">Quantity of labels:</label>
      <input
        type="number"
        class="form-control"
        id="buildOptionsForm"
        formControlName="quantity"
        min="0"
        max="50"
        value="50"
        [ngClass]="{ 'is-invalid': !buildOptionsForm.get('quantity').valid }"
      />
      <small class="form-text text-muted">
        {{
        buildOptionsForm.get("quantity").valid
          ? "Total labels to print. Write a number between 0 and 50"
          : "Please write a number between 0 and 50"
        }}
      </small>
    </div>

    <!-- <div class="form-group col-sm-12">
      <label for="buildOptionsForm__date">Select custom Carrier:</label>
      <input type="checkbox" formControlName="customCarrier" #customCarrier>
    </div>

    <div class="form-group col-sm-12" *ngIf="customCarrier.checked">
      <label>Available Carriers</label>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Carrier:</label>
        <select
          formControlName="selectedCarrier" class="form-control">
          <option
            *ngFor="let carrier of carrierList"
            [value]="carrier.carrierName"
            [selected]="buildOptionsForm.get('selectedCarrier').value === carrier.carrierName">
            {{ carrier.carrierName }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Service:</label>
        <select formControlName="selectedService" class="form-control">
          <option
            *ngFor="let service of carrierSelectedServices"
            [value]="service"
            [selected]="buildOptionsForm.get('selectedService').value === service">
            {{ service }}
          </option>
        </select>
      </div>
    </div> -->
  </form>
</app-modal>

<app-modal
  title="Print By Tracking Numbers"
  secondaryBtn="Cancel"
  primaryBtn="Submit & Print"
  (atCloseBtn)="printByTrackingNumbers = false"
  (atSecondaryBtn)="printByTrackingNumbers = false"
  [show]="printByTrackingNumbers"
  (atPrimaryBtn)="submitTrackingNumbers()"
>
  <form novalidate class="row">
    <div class="form-group col-sm-6">
      <label>List of tracking Numbers</label>
      <input
        #csvInputFile
        name="csvInputFile"
        required
        type="file"
        class="form-control-file"
        accept="text/csv"
      />
    </div>
  </form>
</app-modal>


<ng-container *ngIf="isManifestConfirmationModalActive">
  <div
    class="modal"
    [ngStyle]="{
      'display': 'block',
      'backgroundColor': 'rgba(0,0,0,.8)'
    }"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button (click)="closeManifestConfirmationModal()" aria-label="Close" class="close" data-dismiss="modal"
                  type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to create a manifest?</p>
        </div>
        <div class="modal-footer">
          <button (click)="getTodayShipmentsManifest()" [disabled]="loadingManifests" class="btn btn-primary"
                  type="button">
            {{ loadingManifests ? 'Loading...' : 'Yes'}}
          </button>
          <button (click)="closeManifestConfirmationModal()" [disabled]="loadingManifests" class="btn btn-secondary"
                  type="button">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
