import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { Action } from '@app/models/action.model';

@Injectable()
export class ActionService {
  private apiUrl = environment.apiUrl + '/api';
  private actionsState: Action[] = [];
  private actionsState$: BehaviorSubject<Action[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }

  public getActionsState(): Observable<Action[]> {
    return this.actionsState$;
  }

  public updateActionsState(data: Action[]) {
    this.actions = data;
  }

  private set actions(data: Action[]) {
    this.actionsState = data;
    this.actionsState$.next(data);
  }

  private get actions(): Action[] {
    return this.actionsState.slice();
  }

  public fetchActionsByUserId(subscriptionId: number): Observable<Action[]> {
    return this.http.get(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/actions`)
      .pipe(
        map((response: any) => {
          const newState = this.mergeState(this.actions, response.data.map(action => new Action(action)));
          this.updateActionsState(newState);
          return newState;
        })
      );
  }

  // UTILS
  private mergeState(oldState: Action[], newState: Action[]) {
    if (oldState.length === 0) {
      return newState;
    }
    return newState.reduce((all, current) => {
      const temp = 'length' in all ? all : [];
      const index = temp.findIndex((t: Action) => t.id === current.id);
      if (index === -1) {
        return [...temp, current];
      } else {
        return [
          ...temp.slice(0, index),
          current,
          ...temp.slice(index + 1)
        ];
      }
    }, oldState);
  }
}
