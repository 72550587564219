import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Modules
import { SharedModule } from '@app/shared/shared.module';
// Services
import { DashboardService } from './services/dashboard.service';
import { StatsService } from './services/stats.service';
import { DisputeModalService } from "@app/dashboard/services/dispute-modal.service";
// Components
import * as fromComponents from './components';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard.routing';
import { RetentionComponent } from './pages/retention/retention.component';
import { CustomerShipmentsComponent } from './containers/customer-shipments/customer-shipments.component';
import { ShipmentItemComponent } from '@app/dashboard/components';
import { SearchComponent } from '@app/dashboard/components/search/search.component';
import { DisputeModalComponent } from "@app/dashboard/components/dispute-modal/dispute-modal.component";

const SERVICES = [
  DashboardService,
  StatsService,
  DisputeModalService,
];

const COMPONENTS = [
  DashboardComponent,
  RetentionComponent,
  CustomerShipmentsComponent,
  ShipmentItemComponent,
  SearchComponent,
  DisputeModalComponent,
  ...fromComponents.components,
];

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
  ],
  providers: SERVICES,
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class DashboardModule {}
