export class AlertModel {
  id?: string;
  alertsCode: string;
  title: string;
  message: string;
  timeout?: number;
  type: string;

  constructor(data) {
    this.id = data.id || btoa(Math.random().toString());
    this.title = data.title;
    this.message = data.message;
    this.timeout = data.timeout;
    this.type = data.type;
    this.alertsCode = data.alertsCode;
  }
}
