import { Component, OnInit, ElementRef, HostBinding, Input } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';

import { AlertService } from '../alert.service';
import { AlertModel } from '../alert.model';

@Component({
  selector: 'app-bar-alert',
  template: `
    <app-bar-alert-item 
      [alertId]="item.id"
      [title]="item.title"
      [message]="item.message"
      [color]="item.type"
      [onClose]="onCloseAlert.bind(this)"
      *ngFor="let item of alerts;">
    </app-bar-alert-item>
  `,
  styleUrls: ['./bar-alert.component.scss']
})
export class BarAlertComponent implements OnInit {
  public alerts: AlertModel[];
  public alerts$: ISubscription;
  @Input('alerts-code') alertsCode;
  @Input('x-align') xAling;
  @Input('y-align') yAling;

  constructor(private el: ElementRef,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.setHostStyleClasses();
    this.alerts$ = this.alertService.alertsStore$.subscribe((alerts: AlertModel[]) => {
      this.alerts = alerts.filter(a => a.alertsCode === this.alertsCode)
    });

  }

  setHostStyleClasses() {
    if (this.xAling === 'center' || this.xAling === 'left' || this.xAling === 'right') {
      console.log('esta centrado')
      this.el.nativeElement.classList.add(`bart-alert--${this.xAling}`);
    }
  }

  onCloseAlert(alertId: string) {
    this.alertService.removeAlert(alertId);
  }

}
