import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import * as moment from 'moment';

import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, flatMap, map, mergeMap } from 'rxjs/operators';
import * as LabelPrinter from '@config/label-printer.json';
// Store
import { Store } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity/src/models';
import * as fromAddress from './../store/selectors/address.selectors';
import * as fromAddressActions from './../store/actions/address.actions';
// Services
import { LabelBuildOptions } from './../services/shipment.service';
import { AlertService } from '@app/shared/components/alerts/alert.service';
import * as fromServices from './../services';
import { SocketIoService } from '@app/core/services';
// Models
import { Shipment, ShipmentStats } from './../models/shipment.model';
import { Customer } from './../models/customer.model';
import { Address } from '@app/models/address.model';
import { LocalStorageService } from 'ngx-webstorage';
import { ManifestsService } from './manifests-list/services/manifest.service';

@Component({
  selector: 'app-shipments-labels',
  templateUrl: './shipments-labels.component.html',
  styleUrls: ['./shipments-labels.component.scss'],
})
export class ShipmentsLabelsComponent implements OnInit, OnDestroy {
  totalShipmentsBeingPrinted: number = 0;

  labelPrinterData = (LabelPrinter as any).default;
  loadingShipment = false;
  page = 1;
  searchPage = 1;
  shipmentsLabelsRows: ShipmentsLabelsRows[] = [];
  showSearchLabelsField = false;
  onSearchLabel$: Subject<HTMLInputElement> = new Subject();
  hidePrintedLabels = false;
  downloadingPdf = false;
  downloadingPdfByTrackingCodes = false;
  shipmentsStats: ShipmentStats;
  loadingShipmentsStats = false;
  showPrintingModal = false;
  isManifestConfirmationModalActive = false;
  printByTrackingNumbers = false;
  addresses$: Observable<Dictionary<Address>>;
  loadingManifests = false;
  public showBuildsOptions = false;
  public buildOptionsForm: FormGroup;
  @ViewChild('csvInputFile', { static: false }) csvInputFile: ElementRef;
  carrierList: any[] = [
    {
      carrierName: 'USPS',
      services: [
        'First',
        'Priority',
        'Express',
        'ParcelSelect',
        'LibraryMail',
        'MediaMail',
        'FirstClassMailInternational',
        'FirstClassPackageInternationalService',
        'PriorityMailInternational',
        'ExpressMailInternational',
      ]
    },
    {
      carrierName: 'UPSMailInnovations',
      services: [
        'First',
        'Priority',
        'ExpeditedMailInnovations',
        'PriorityMailInnovations',
        'EconomyMailInnovations',
      ]
    },
    {
      carrierName: 'DhlEcs',
      services: [
        'DHLParcelExpedited',
        'DHLParcelExpeditedMax',
        'DHLParcelGround',
      ]
    }
  ];
  carrierSelectedServices = [];
  currentFormattedMonth = moment().format('MMMM YYYY');
  monthlyStatsList = [];
  optionStats: any = {};
  showALl = false;
  // IO events
  onBuildFailedSubscription: Subscription = this.socketIO.listen('SHIPMENTS__BUILD-FAILED')
    .subscribe(data => {
      this.downloadingPdf = false;
      this.alertService.danger({
        alertsCode: 'sl-alerts',
        title: `Can't create the build`,
        message: data.message ? data.message : JSON.stringify(data),
        timeout: 10000
      });
    });

  onBuildGenerated: Subscription = this.socketIO.listen('SHIPMENTS__BUILD-FINISHED')
    .subscribe(({ pdfUrl }) => {
      this.downloadingPdfByTrackingCodes = false;
      this.downloadingPdf = false;
      window.open(pdfUrl);
    });
  private serverUrl: string = environment.apiUrl;
  private shipments: Shipment[] = [];
  private customers: Customer[] = [];
  private searchingText: string;

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private customerService: fromServices.CustomerService,
    private shipmentService: fromServices.ShipmentService,
    private store: Store<any>,
    private formBuilder: FormBuilder,
    private socketIO: SocketIoService,
    private localStorage: LocalStorageService,
    private manifestService: ManifestsService
  ) {
  }

  ngOnInit() {
    this.addresses$ = this.store.select(fromAddress.getAddressEntities);
    this.el.nativeElement.classList.add('container');

    this.shipmentService.getShipmentsState()
      .subscribe(shipments => {
        this.shipments = shipments;
        this.shipmentsLabelsRows = this.getFormatedShipmentsLabelsRows(this.shipments, this.customers);
      });

    this.customerService.getCustomersState()
      .subscribe(customers => {
        this.customers = customers;
        this.shipmentsLabelsRows = this.getFormatedShipmentsLabelsRows(this.shipments, this.customers);
      });

    this.loadShipments();

    this.onSearchLabel$
      .pipe(
        map(element => {
          this.searchingText = element.value;
          return element;
        }),
        debounceTime(1500)
      )
      .subscribe((target) => this.onSearchLabel(target));

    this.updateShipmentsStats();
    this.configureBuildOptionsForm();
  }

  ngOnDestroy() {
    this.onBuildFailedSubscription.unsubscribe();
    this.onBuildGenerated.unsubscribe();
  }

  loadShipments() {
    this.fetchShipmentsAndUsersFromApi()
      .subscribe(results => {
        this.shipmentService.updateShipmentsState(results.shipments);
        this.customerService.updateCustomersState(results.customers);
      }, e => console.log(e));
  }

  fetchShipmentsAndUsersFromApi(page = 0, limit = 25) {
    this.loadingShipment = true;
    return this.shipmentService.fecthShipmentsFromApi({ limit, page })
      .pipe(
        mergeMap((shipments: Shipment[]) => this.customerService.fetchCustomersByIdsFromApi({
          ids: shipments.map(shipment => shipment.userId),
          limit,
          page: undefined
        })
          .pipe(
            map((customers: Customer[]) => {
              this.store.dispatch(new fromAddressActions.LoadCustomersAddressesAction({
                ids: customers.map(customer => customer.id)
              }));
              this.loadingShipment = false;
              return { customers, shipments };
            })
          ))
      );
  }

  getFormatedShipmentsLabelsRows(shipments: Shipment[], customers: Customer[]): ShipmentsLabelsRows[] | undefined[] {
    if ((typeof shipments === 'undefined' || shipments.length === 0)
      || (typeof shipments === 'undefined' || customers.length === 0)) {
      return [];
    }

    return shipments.reduce((allRows, currentShipment) => {
      const temp = 'length' in allRows ? allRows : [];
      const currentCustomer = customers[customers.findIndex(c => c.id === currentShipment.userId)];
      if (typeof currentCustomer === 'undefined') {
        return temp;
      }

      return [...temp, {
        shipmentId: currentShipment.id,
        userId: currentCustomer.id,
        address: currentShipment.address,
        userName: `${currentCustomer.firstName} ${currentCustomer.lastName}`,
        labelUrl: typeof currentShipment.shippingLabel !== 'undefined' ? currentShipment.shippingLabel.labelUrl : undefined,
        printed: currentShipment.printed.isPrinted,
        refunded: currentShipment.tracker ? currentShipment.tracker.refunded : false,
        cancelled: currentShipment.cancelled,
      }];
    }, []);
  }

  getShipmentAddress(shipment: Shipment): Observable<Address> {
    return this.store.select(fromAddress.getAddressEntities)
      .pipe(
        map((addresses: fromAddress.Entities) => addresses[shipment.address])
      );
  }

  filteredLabels(shipmentsLabelsRows: ShipmentsLabelsRows[]) {
    let filteredShipmentsLabelsRows = shipmentsLabelsRows;
    const searchText = this.searchingText;
    if (typeof searchText !== 'undefined' && searchText.length >= 1) {
      filteredShipmentsLabelsRows = filteredShipmentsLabelsRows.filter(fslr =>
        fslr.userName.toLowerCase().includes(searchText.toLowerCase())
        || fslr.address.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (this.hidePrintedLabels) {
      filteredShipmentsLabelsRows = filteredShipmentsLabelsRows.filter(fslr => fslr.printed === false);
    }
    return filteredShipmentsLabelsRows;
  }

  onLoadMore(page: number) {
    this.loadingShipment = true;
    this.fetchShipmentsAndUsersFromApi(0, 25 * page)
      .subscribe((results: { shipments: Shipment[], customers: Customer[] }) => {
        this.loadingShipment = false;
        if (results.shipments.length !== 0 && results.customers.length !== 0) {
          this.page = page;
        }
      });
  }

  onPrintLabel(shipmentId: string) {
    this.loadingShipment = true;
    this.shipmentService.fetchShipmentLabelToPrint(shipmentId)
      .pipe(
        mergeMap((pdfUrl: any) => {
          window.open(pdfUrl);
          return this.shipmentService.setShipmentAsPrinted(shipmentId);
        })
      )
      .subscribe((shipment: Shipment) => {
        this.loadingShipment = false;
        this.updateShipmentsStats();
      }, ({ error }) => {
        this.downloadingPdf = false;
        this.alertService.danger({
          alertsCode: 'sl-alerts',
          title: `Can't print the label`,
          message: error.message ? error.message : JSON.stringify(error),
          timeout: 10000
        });
      });
  }

  onSearchLabel(element: HTMLInputElement) {
    this.searchPage = 1;
    if (element.value.length === 0) {
      return;
    }
    this.customerService.fetchCustomersByNameAndEmailFromApi(element.value, 0, 100 * this.searchPage + 1)
      .pipe(
        flatMap(customers => {
          const customersIds = customers.map(c => c.id);
          return this.shipmentService.fetchShipmentsByUsers(customersIds, 0, 100 * this.searchPage + 1);
        })
      )
      .subscribe(shipments => {
        this.searchPage = this.searchPage + 1;
      });
  }

  onShowHidePrintedLabels() {
    this.hidePrintedLabels = !this.hidePrintedLabels;
  }

  updateShipmentsStats() {
    this.loadingShipmentsStats = true;

    this.shipmentService.getTotalShipmentsBeingPrinted()
      .subscribe(total => {
        console.log({ total })
        this.totalShipmentsBeingPrinted = total;
      });

    this.shipmentService.fetchShipmentsStatsFromApi()
      .subscribe(stats => {
        this.shipmentsStats = stats;
        if (this.shipmentsStats && this.shipmentsStats.monthlyStats) {
          this.monthlyStatsList = Object
            .keys(this.shipmentsStats.monthlyStats)
            .sort((a, b) => {
              const dateA = moment(a, 'MMMM YYYY');
              const dateB = moment(b, 'MMMM YYYY');
              return dateA.isBefore(dateB) ? 1 : -1;
            });
          this.buildOptionsForm.get('date').patchValue(this.currentFormattedMonth);
        }
        this.loadingShipmentsStats = false;
      });
  }

  getTodayShipmentsManifest() {
    this.loadingManifests = true;
    this.manifestService.generateManifest()
      .subscribe((response) => {
        this.loadingManifests = false;
        this.closeManifestConfirmationModal();
      }, (error) => {
        console.log(error);
        const serverError = error.error || error;
        this.loadingManifests = false;
        this.closeManifestConfirmationModal();
        this.alertService.danger({
          alertsCode: 'sl-alerts',
          title: `Can't create manifest.`,
          message: serverError.message ? serverError.message : JSON.stringify(serverError),
          timeout: 10000
        });
      });
  }

  openManifestConfirmationModal() {
    this.isManifestConfirmationModalActive = true;
  }

  closeManifestConfirmationModal() {
    this.isManifestConfirmationModalActive = false;
  }

  updateCarrierServicesList(carrierName) {
    const serviceControl = this.buildOptionsForm.get('selectedService');
    const carrier = this.carrierList.find(e => e.carrierName === carrierName);
    this.carrierSelectedServices = carrier ? carrier.services : [];

    if (!this.carrierSelectedServices.some(e => e === serviceControl.value)) {
      serviceControl.setValue(null);
    }
  }

  public onPrintBuild(value: LabelBuildOptions) {
    this.showBuildsOptions = false;
    this.downloadingPdf = true;
    if (this.buildOptionsForm.valid) {
      this.shipmentService.fetchUnprintedShipmentsPdf(value)
        .subscribe((_: any) => {
          this.alertService.success({
            alertsCode: 'sl-alerts',
            title: 'Build is being generated',
            message: '',
            timeout: 5000
          });
        }, ({ error }) => {
          this.downloadingPdf = false;
          this.alertService.danger({
            alertsCode: 'sl-alerts',
            title: `Can't create the build`,
            message: error.message ? error.message : JSON.stringify(error),
            timeout: 10000
          });
        });
      this.configureBuildOptionsForm();
    }
  }

  public parseMonth(num: number) {
    return [undefined, 'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'][num];
  }

  // ---- COMPONENT HELPERS
  downloadPdf(pdfBytes, pdfName) {
    const newBlob = new Blob([pdfBytes], { type: 'application/pdf' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    link.download = pdfName + '.pdf';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  submitTrackingNumbers() {
    this.printByTrackingNumbers = false;
    const csvFile = this.csvInputFile.nativeElement.files;
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = (e.target as any).result;
      const trackingCodes = result ? result.split(/\r?\n|\r/g) : [];

      this.downloadingPdfByTrackingCodes = true;
      this.shipmentService.fetchShipmentLabelByTrackingNumbers(trackingCodes)
        .subscribe(
          _ => {
            this.alertService.success({
              alertsCode: 'sl-alerts',
              title: 'Build is being generated',
              message: '',
              timeout: 5000
            });
          },
          error => {
            this.downloadingPdfByTrackingCodes = false;
            this.alertService.danger({
              alertsCode: 'sl-alerts',
              title: `Can't create the build`,
              message: error.message ? error.message : JSON.stringify(error),
              timeout: 10000
            });
          });
    };

    reader.readAsText(csvFile[0]);
  }

  getTypeStats(selectedDate) {
    this.loadingShipmentsStats = true;
    this.shipmentService.fetchShipmentsStatsByTypeFromApi(selectedDate)
      .subscribe(stats => {
        console.log(stats);
        this.optionStats = stats;
        this.loadingShipmentsStats = false;
      });
  }

  // ---- Printing options
  private configureBuildOptionsForm() {
    this.buildOptionsForm = this.formBuilder.group({
      month: this.formBuilder.control('ALL', [Validators.required]),
      option: this.formBuilder.control('ALL', [Validators.required]),
      date: this.formBuilder.control('ALL', [Validators.required]),
      quantity: this.formBuilder.control(50, [Validators.required, Validators.min(0), Validators.max(50)]),
      selectedCarrier: [undefined],
      selectedService: [undefined],
      // customCarrier: [],
    });

    this.buildOptionsForm.get('selectedCarrier').valueChanges.subscribe((value) => {
      for (let i = 0; i < this.labelPrinterData.defaultBuildRates.length; i++) {
        const rateObject = this.labelPrinterData.defaultBuildRates[i];
        if (rateObject.carrier === value) {
          this.buildOptionsForm.get('selectedService').setValue(rateObject.rate);
          break;
        }
      }
    });

    this.buildOptionsForm.get('date').valueChanges.subscribe((value) => {
      console.log(value);
      this.getTypeStats(value);
    });


    // this.buildOptionsForm.get('customCarrier').valueChanges.subscribe((value) => {
    //   const validations = value ? [Validators.required] : [];
    //   ['selectedCarrier', 'selectedService'].forEach((item) => {
    //     this.buildOptionsForm.get(item).setValidators(validations);
    //     this.buildOptionsForm.get(item).updateValueAndValidity();
    //   });

    //   if (!value) {
    //     this.buildOptionsForm.get('selectedCarrier').reset();
    //     this.buildOptionsForm.get('selectedService').reset();
    //   } else {
    //     this.buildOptionsForm.get('selectedCarrier').setValue('UPSMailInnovations');
    //     this.buildOptionsForm.get('selectedService').setValue('ExpeditedMailInnovations');
    //   }
    // });

    // this.buildOptionsForm.get('selectedCarrier').valueChanges.subscribe((value) => {
    //   this.updateCarrierServicesList(value);
    // });
  }
}

export interface ShipmentsLabelsRows {
  shipmentId: string;
  userId: string;
  userName: string;
  address: string;
  labelUrl: string;
  printed: boolean;
  cancelled: boolean;
}
