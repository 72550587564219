<ul class="row dsh-ships">
  <li *ngFor="let shipment of shipments; let lastShipment = last" class="col-sm-12 dsh-ships__item"
      [ngClass]="{ 'dsh-section--loading': isLoading(shipment.id) }">
    <app-shipment-item
      [shipment]="shipment"
      [showEditMessage]="lastShipment"
      [subscription]="subscription"
      [giftItMessage]="getGiftItMessage(shipment)"
      (buyShipment)="onBuyShipmentAction(shipment)"
      (printLabel)="onPrintLabel(shipment?.id)"
      (getLabel)="onGetLabel(shipment?.id)"
      (voidLabel)="onVoidPressed(shipment.id)"
      (printDate)="onChangePrintDateAction(shipment)"
      (addressChange)="onChangeAddress(shipment)"
      (receiverChange)="onReceiverChange(shipment)"
      (reshipLabel)="onReshipAction(shipment.id, false)">
    </app-shipment-item>
  </li>
</ul>

<!-- Buy shipment label modal -->
<app-modal title="Buy shipment label"
           [show]="showBuyShipmentModal"
           (atCloseBtn)="showBuyShipmentModal = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showBuyShipmentModal = false"
           primaryBtn="Buy Label"
           [disablePrimaryBtn]="buyShipmentForm.get('selectedCarrier').value === null || isLoading(buyShipmentForm.get('shipment').value)"
           (atPrimaryBtn)="buyShipment()">

  <form novalidate [formGroup]="buyShipmentForm">
    <div class="form-group">
      <label for="buyShipmentForm__shipmentId">Shipment id</label>
      <input style="pointer-events: none;" id="buyShipmentForm__shipmentId" class="form-control" type="text"
             formControlName="shipment"
             placeholder="Shipment ID">
    </div>

    <div *ngIf="showBuyShipmentModal && !shipmentRatesRequested; else ratesLoading" class="form-group">
      <label>Shipment rates</label>
      <div class="form-group">
        <label for="buildOptionsForm__date">Select Carrier:</label>
        <select
          formControlName="selectedCarrier"
          class="form-control"
          (change)="onChangeSelectedCarrier($event)">
          <option
            *ngFor="let rate of byKeys(shipmentRates)"
            [value]="rate"
            [selected]="this.buyShipmentForm.get('selectedCarrier').value === rate">
            {{ rate }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="buildOptionsForm__date">Select a Class:</label>
        <select formControlName="selectedService" class="form-control">
          <option
            *ngFor="let service of byKeys(shipmentRates[this.buyShipmentForm.get('selectedCarrier').value])"
            [value]="service"
            [selected]="this.buyShipmentForm.get('selectedService').value === service">
            {{ service }}
          </option>
        </select>
      </div>
    </div>

    <ng-template #ratesLoading>
      <p>Loading...</p>
    </ng-template>
  </form>

</app-modal>

<!-- Re-ship reasons -->
<app-modal title="What are the reasons to create this reship?"
           [show]="showReshipReasonForm"
           (atCloseBtn)="showReshipReasonForm = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showReshipReasonForm = false"
           primaryBtn="Create label"
           [disablePrimaryBtn]="isLoading(reshipReasonForm.get('shipmentId').value)"
           (atPrimaryBtn)="onReshipPressed()">

  <form novalidate [formGroup]="reshipReasonForm">
    <div class="form-group">
      <label for="reshipReasonForm__shipmentId">Subscription id</label>
      <input id="reshipReasonForm__shipmentId" class="form-control" type="text" formControlName="shipmentId"
             placeholder="Subscription ID">
    </div>

    <div formArrayName="reasons">
      <div *ngFor="let reason of reshipReasonForm['controls'].reasons['controls']; let i = index"
           class="input-group mb-3" formGroupName="{{i}}">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input type="checkbox" formControlName="enabled">
          </div>
        </div>
        <input [ngClass]="{'col-3': reason.get('name').value === 'Other' && reason.get('enabled').value}" type="text"
               class="form-control"
               formControlName="name" style="pointer-events: none;">
        <textarea *ngIf="reason.get('name').value === 'Other' && reason.get('enabled').value"
                  placeholder="Write a reason" formControlName="value"
                  class="form-control"></textarea>
      </div>
    </div>
  </form>

</app-modal>


<!-- Change printing date -->
<app-modal title="Change printing date"
           [show]="showChangePrintingDateModal"
           (atCloseBtn)="showChangePrintingDateModal = false"
           secondaryBtn="Cancel"
           (atSecondaryBtn)="showChangePrintingDateModal = false"
           primaryBtn="Change Date"
           [disablePrimaryBtn]="changePrintingDateForm.get('shipmentId').value === null ||
            !changePrintingDateForm.get('cantPrintUntil').value.trim().length ||
            isLoading(changePrintingDateForm.get('shipmentId').value)"
           (atPrimaryBtn)="changePrintingDate()">

  <form novalidate [formGroup]="changePrintingDateForm">
    <div class="form-group">
      <label>Printing Date</label>
      <input class="form-control"
             type="text"
             name="cantPrintUntil"
             formControlName="cantPrintUntil"
             placeholder="YYYY-MM-DD">
    </div>

  </form>

</app-modal>


<app-modal
  [show]="showChangeShipmentAddress"
  (atCloseBtn)="showChangeShipmentAddress = false"
  (atSecondaryBtn)="showChangeShipmentAddress = false"
  (atPrimaryBtn)="updateShipmentAddress()"
  primaryBtn="Update Address"
  secondaryBtn="Cancel"
  title="Change shipment address">
  <!-- Edit address modal -->
  <form novalidate [formGroup]="shipmentEditAddressForm">
    <div class="form-group">
      <label>Address</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="shippingAddress" placeholder="Address"/>
        <input type="text" class="form-control" formControlName="shippingAddressExtra"
               placeholder="Address extra"/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="city" placeholder="City"/>
        <input type="text" class="form-control" formControlName="state" placeholder="State"/>
        <input type="text" class="form-control" formControlName="stateCode" placeholder="State Code"/>
      </div>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="zip" placeholder="Zip code"/>
        <input type="text" class="form-control" formControlName="country" placeholder="Country"/>
        <input type="text" class="form-control" formControlName="countryCode" placeholder="Country Code"/>
      </div>
    </div>
  </form>

</app-modal>

<app-modal
  [show]="showChangeShipmentReceiver"
  (atCloseBtn)="showChangeShipmentReceiver = false"
  (atSecondaryBtn)="showChangeShipmentReceiver = false"
  (atPrimaryBtn)="updateShipmentReceiver()"
  primaryBtn="Update Receiver"
  secondaryBtn="Cancel"
  title="Change shipment receiver">
  <form novalidate [formGroup]="shipmentReceiverForm">
    <div class="form-group">
      <label>Receiver Name</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="name" placeholder="Receiver Name"/>
      </div>
    </div>
  </form>
</app-modal>
