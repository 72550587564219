<div class="modal fade" [ngClass]="{ 'show': show }" [ngStyle]="{
  'display': show ? 'block' : 'none',
  'backgroundColor': show ? 'rgba(0,0,0,.8)' : 'transparent'
}" style="overflow-y: scroll;">
  <div class="modal-dialog modal-dialog-centered"
    [ngClass]="{
      'modal-lg': large
    }">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button (click)="atCloseBtn.emit($event)" type="button" class="close">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button *ngIf="secondaryBtn !== undefined" (click)="atSecondaryBtn.emit($event)" type="button" class="btn">
          {{ secondaryBtn }}
        </button>
        <button *ngIf="primaryBtn !== undefined" [disabled]="disablePrimaryBtn"
                (click)="atPrimaryBtn.emit($event)"
                type="button"
                class="btn btn-secondary"
                [ngClass]="btnPrimaryClass">
          {{ primaryBtn }}
        </button>
      </div>
    </div>
  </div>
</div>
