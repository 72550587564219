<app-modal
    title="Dispute data"
    [show]="open$ | async"
    (atCloseBtn)="closeModal()">

  <div class="row">
    <div class="col-sm-12 dispute-section">
      <h5>User</h5>
      <p>
        <b>Date created:</b> {{ (disputeData$ | async)?.customer?.createdAt && utcToPST((disputeData$ | async)?.customer?.createdAt) }}<br>
        <b>User's IP address (Last one):</b> {{ (disputeData$ | async)?.customer?.IP }}<br>
        <b>Customer's name:</b> {{ (disputeData$ | async)?.customer?.firstName }} {{ (disputeData$ | async)?.customer?.lastName }}
      </p>
    </div>

    <div class="col-sm-12 dispute-section">
      <h5>UTM Parameters</h5>
      <p>
        <b>Source:</b> {{ (disputeData$ | async)?.customer?.utmData?.utm_source }} <br>
        <b>Medium:</b> {{ (disputeData$ | async)?.customer?.utmData?.utm_medium }} <br>
        <b>Campaign:</b> {{ (disputeData$ | async)?.customer?.utmData?.utm_campaign }} <br>
        <b>Content:</b> {{ (disputeData$ | async)?.customer?.utmData?.utm_content }}
      </p>
    </div>

    <div class="col-sm-12 dispute-section">
      <h5>Subscription</h5>
      <p>
        <b>Gift:</b> {{ (disputeData$ | async)?.subscription?.isGift }}<br>
        <b>Recipient's Name:</b> {{ (disputeData$ | async)?.subscription?.recipient?.firstName }} {{ (disputeData$ | async)?.subscription?.recipient?.lastName }}<br>
        <b>Recipient's address:</b> {{ (disputeData$ | async)?.address?.label }}
      </p>
    </div>

    <div class="col-sm-12 dispute-section">
      <h5>Shipments</h5>
      <p *ngFor="let shipment of (disputeData$ | async)?.shipments">
        <b>Printed on:</b> {{ shipment.printed?.printedAt && utcToPST(shipment.printed?.printedAt) }} <br>
        <b>Tracking Number:</b> {{ shipment.tracker?.trackingCode }} <br>
        <b>Status</b> {{ shipment.tracker?.status }} <br>
      </p>
    </div>

    <div class="col-sm-12 dispute-section">
      <h5>Actions</h5>
      <p *ngFor="let action of (disputeData$ | async)?.actions">
        <b>Value:</b> {{ action.value }}
        <b>Date:</b> {{ action.createdAtPt }}
      </p>
    </div>

    <div class="col-sm-12 dispute-section">
      <h5>Payments:</h5>
      <p *ngFor="let payment of (disputeData$ | async)?.payments">
        <b>Amount:</b> {{ payment.amount }} <br>
        <b>Date:</b> {{ payment.createdAtPt }} <br>
        <b>Braintree ID:</b> {{ payment.braintreeTransactionId || payment.transactionId }}
      </p>
    </div>
  </div>
</app-modal>
