import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Customer } from "@app/models/customer.model";
import { Subscription } from "@app/models/subscription.model";
import { Shipment } from "@app/models/shipment.model";
import { Action } from "@app/models/action.model";
import { Payment } from "@app/models/payment.model";
import { Address } from "@app/models/address.model";

@Injectable()
export class DisputeModalService {
  open$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disputeData$: BehaviorSubject<IDisputeData> = new BehaviorSubject<IDisputeData>({
    cleared: false,
  });

  constructor() { }

  setModalStatus(status: boolean): void {
    this.open$.next(status);
  }

  setDisputeState(data) {
    console.log(data)
    const currentState = this.disputeData$.getValue();
    this.disputeData$.next({
      ...currentState,
      customer: data.customer,
      subscription: data.subscription,
      shipments: data.shipments,
      actions: data.actions,
      payments: data.payments,
      address: data.address,
    });
  }
}

export interface IDisputeData {
  cleared: boolean;
  customer?: Customer;
  subscription?: Subscription;
  shipments?: Shipment[];
  actions?: Action[];
  payments?: Payment[];
  address?: Address;
}
