import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

// rxjs
import { map } from 'rxjs/operators';

@Injectable()
export class BraintreeService {

  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private httpClient: HttpClient,
  ) { }

  getClientAuthorizationToken(): Promise<string> {
    return this.httpClient
      .get(`${this.apiUrl}/braintree/client_token`)
      .pipe(map((result: any) => result.client_token))
      .toPromise();
  }
}
