import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors';
const ɵ0 = (state) => state.data;
export const getAdminData = createSelector(fromRoot.getAdminState, ɵ0);
const ɵ1 = (state) => state.isAuthenticated;
export const getUserIsAuthenticated = createSelector(fromRoot.getAdminState, ɵ1);
const ɵ2 = (state) => state.error;
export const getUserError = createSelector(fromRoot.getAdminState, ɵ2);
const ɵ3 = (state) => state.loaded;
export const getUserLoaded = createSelector(fromRoot.getAdminState, ɵ3);
const ɵ4 = (state) => state.loading;
export const getUserLoading = createSelector(fromRoot.getAdminState, ɵ4);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
