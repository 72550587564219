import { Customer } from './customer.model';

export class Address {
  id: string;
  user: string;
  shippingAddress: string;
  shippingAddressExtra: string;
  line: string;
  formattedLine: string;
  state: string;
  stateCode: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
  label: string;
  createdAt: Date;
  updatedAt: Date;
  constructor(data) {
    this.id = data._id;
    this.user = data.user;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.shippingAddress = data.shippingAddress;
    this.shippingAddressExtra = data.shippingAddressExtra;
    this.line = data.line;
    this.formattedLine = data.formattedLine;
    this.state = data.state;
    this.stateCode = data.stateCode;
    this.city = data.city;
    this.zip = data.zip;
    this.country = data.country;
    this.countryCode = data.countryCode;
    this.label = data.label;
  }
}
