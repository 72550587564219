import { createEntityAdapter } from '@ngrx/entity';
import * as addressActions from './../../store/actions/address.actions';
const ɵ0 = (address) => address.id;
export const adapter = createEntityAdapter({
    selectId: ɵ0,
    sortComparer: false
});
export const initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
});
export function reducer(state = initialState, action) {
    switch (action.type) {
        case addressActions.LOAD_CUSTOMER_ADDRESSES:
        case addressActions.LOAD_CUSTOMERS_ADDRESSES: {
            return Object.assign({}, state, { loading: true, loaded: false });
        }
        case addressActions.LOAD_CUSTOMER_ADDRESSES_COMPLETE:
        case addressActions.LOAD_CUSTOMERS_ADDRESSES_COMPLETE: {
            return adapter.addAll(action.payload, Object.assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case addressActions.ADD_ADDRESS_COMPLETE: {
            return adapter.addOne(action.payload, Object.assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case addressActions.UPDATE_ADDRESS_COMPLETE: {
            return adapter.updateOne(action.payload, Object.assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case addressActions.LOAD_CUSTOMERS_ADDRESSES_FAIL:
        case addressActions.LOAD_CUSTOMER_ADDRESSES_FAIL: {
            return Object.assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export const getAddresses = (state) => state.entities;
export const getAddressesLoaded = (state) => state.loaded;
export const getAddressesLoading = (state) => state.loading;
export const getAddressesError = (state) => state.error;
export { ɵ0 };
