import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() show = false;
  @Input() title: string;
  @Input() primaryBtn: string;
  @Input() disablePrimaryBtn: boolean;
  @Input() secondaryBtn: string;
  @Input() large = false;
  @Input() btnPrimaryClass: string;
  @Output() atPrimaryBtn: EventEmitter<any> = new EventEmitter();
  @Output() atSecondaryBtn: EventEmitter<any> = new EventEmitter();
  @Output() atCloseBtn: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

}
