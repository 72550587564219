import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as addressActions from '@app/store/actions/address.actions';
// models
// services
import { AddressService } from '@app/services';
export class AddressEffects {
    constructor(addressService, actions$, store) {
        this.addressService = addressService;
        this.actions$ = actions$;
        this.store = store;
        this.loadCustomerAddresses$ = this.actions$
            .pipe(ofType(addressActions.LOAD_CUSTOMER_ADDRESSES))
            .pipe(map((action) => action.payload), switchMap((data) => {
            return this.addressService
                .getCustomerAddresses({ user: data.id })
                .pipe(map(addresses => this.store.dispatch(new addressActions.LoadCustomerAddressesCompleteAction(addresses))), catchError(reason => {
                this.store.dispatch(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
                return of(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
            }));
        }));
        this.loadByCustomerIds$ = this.actions$
            .pipe(ofType(addressActions.LOAD_CUSTOMERS_ADDRESSES))
            .pipe(map((action) => action.payload), switchMap(({ ids }) => {
            return this.addressService
                .getAddressesByUserIds({ ids })
                .pipe(map(addresses => this.store.dispatch(new addressActions.LoadCustomersAddressesCompleteAction(addresses))), catchError(reason => {
                this.store.dispatch(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
                return of(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AddressEffects.prototype, "loadCustomerAddresses$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AddressEffects.prototype, "loadByCustomerIds$", void 0);
