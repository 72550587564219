import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './../../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const extras: NavigationExtras = {
      queryParams: {
        redirectUrl: state.url
      }
    };

    if (!this.authService.tokenPayload || this.authService.tokenPayload.role !== 'admin') {
      this.router.navigate(['/login'], extras);
    }

    return !!this.authService.tokenPayload;
  }
}
