import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  FormBuilder,
  Validators
} from '@angular/forms';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  showPassword = false;
  showConfirmPassword = false;

  displayErrors = false;
  passwordChangedMessage = false;

  token: string;
  error: string;

  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
  }

  get arePasswordsEquals() {
    const password = this.form.get('password').value;
    const confirmPassword = this.form.get('confirmPassword').value;
    return password === confirmPassword;
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.recoveryToken;
  }

  onSubmit() {

    if (this.form.invalid || !this.arePasswordsEquals) {
      this.displayErrors = true;
      return;
    }
    const { password, confirmPassword } = this.form.getRawValue();
    this.authService.recoveryPassword(this.token, password, confirmPassword)
      .subscribe(response => {
        this.passwordChangedMessage = response;
        // this.router.navigate(['/login']);
      }, err => {
        console.log(err);
        this.error = err.error.message;
      });
  }


}
