import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShipmentsLabelsComponent } from '@app/shipments-labels/shipments-labels.component';
import { ShipmentsCreatorComponent } from '@app/shipments-creator/shipments-creator.component';
import { LoginComponent } from '@app/login/login.component';
import { AuthGuard } from '@app/guards/auth/auth.guard';
import { environment } from '@env/environment';
import { ForgotPasswordComponent } from '@app/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/reset-password/reset-password.component';
import { RecoveryPasswordGuard } from '@app/guards/auth/recovery-password.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    pathMatch: 'full',
  },
  { path: 'shipment-labels', component: ShipmentsLabelsComponent, canActivate: [AuthGuard] },
  { path: 'shipment-creator', component: ShipmentsCreatorComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'recovery-password', component: ResetPasswordComponent, canActivate: [RecoveryPasswordGuard] },
  {
    path: 'coupons-manager',
    loadChildren: 'app/coupons-manager/coupons-manager.module#CouponsManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'rates-manager',
    loadChildren: 'app/rates-manager/rates-manager.module#RatesManagerModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'tokens-manager',
    loadChildren: () => import('app/tokens-manager/tokens-manager.module').then(m => m.TokensManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ahoy-skippers',
    loadChildren: () => import('app/ahoy-skippers/ahoy-skippers.module').then(m => m.AhoySkippersModule),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: environment.production })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
