import { createSelector } from '@ngrx/store';

import * as fromRoot from './../selectors';
import * as fromAddress from './../../store/reducers/address.reducer';
import { Address } from './../../models/address.model';

export { State, Entities } from '@app/store/reducers/address.reducer'

export const {
  selectIds: getAddressesIds,
  selectEntities: getAddressEntities,
  selectTotal: getTotalAddresses,
  selectAll: getAllAddresses,
} = fromAddress.adapter.getSelectors(fromRoot.getAddressState);

export const getAddressesLoaded = createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.loaded
);

export const getSAddressesLoading = createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.loading
);
