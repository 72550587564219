import { Component, OnInit, ElementRef, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bar-alert-item',
  template: `
    <strong>{{ title }}</strong> - <span>{{ message }}</span>
    <button (click)="onClose(alertId)" type="button" class="close">
      <span aria-hidden="true">&times;</span>
    </button>
  `,
  styleUrls: ['./bar-alert-item.component.scss']
})
export class BarAlertItemComponent implements OnInit {
  @Input('alertId') alertId;
  @Input('title') title;
  @Input('message') message;
  @Input('color') color;
  @Input('onClose') onClose;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.setHostStyleClasses();
  }

  setHostStyleClasses() {
    this.el.nativeElement.classList.add('alert');
    this.el.nativeElement.classList.add('show');
    this.el.nativeElement.classList.add(`alert-${this.color}`);
  }

}
