import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { Customer } from '@app/models/customer.model';
import { Action } from '@app/models/action.model';
import { Shipment } from '@app/models/shipment.model';
import { Subscription } from '@app/models/subscription.model';
import { Address } from '@app/models/address.model';
import { Note } from '@app/models/note.model';
import { Payment } from '@app/models/payment.model';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient
  ) {
  }

  fetchDataForDashboard(searchObject): Observable<{
    customers: Customer[],
    actions: Action[],
    shipments: Shipment[],
    subscriptions: Subscription[],
    address: Address[],
  }> {
    const params = new HttpParams({
      fromObject: {
        ...searchObject,
        searchValue: encodeURIComponent(searchObject.searchValue)
      }
    });

    return this.http.get<any>(`${this.apiUrl}/admin/dashboard/search/`, { params });
  }

  fetchAutoComplete(value: string): Observable<{
    addresses?: any[],
    customers?: any[],
    shipments?: any[],
    subscriptions?: any[],
  }> {
    const params = new HttpParams()
      .append('searchValue', encodeURIComponent(value));
    return this.http.get<any>(`${this.apiUrl}/admin/dashboard/search-autocomplete/`, { params })
      .pipe(map(({ data }) => data));
  }
}

export interface DataFromDashboard {
  customers: Customer[];
  actions: Action[];
  shipments: Shipment[];
  subscriptions: Subscription[];
  address: Address[];
  notes: Note[];
  payments: Payment[];
}
