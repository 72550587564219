import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { tap, filter, takeUntil, switchMap } from 'rxjs/operators';
import { ManifestsService, IManifest } from './services/manifest.service';

@Component({
  selector: 'app-manifests-list',
  templateUrl: './manifests-list.component.html',
  styleUrls: ['./manifests-list.component.scss']
})
export class ManifestsListComponent implements OnDestroy {
  showModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingManifests$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  manifests$: BehaviorSubject<IManifest[]> = new BehaviorSubject<IManifest[]>([]);

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  showModalSubscription: Subscription = this.showModal$
    .pipe(tap(value => this.loadingManifests$.next(value)))
    .pipe(filter(data => !!data))
    .pipe(switchMap(_ => this.manifestService.getTodaysManifests()))
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(manifests => {
      this.manifests$.next(manifests);
      this.loadingManifests$.next(false);
    });

  constructor(
    private manifestService: ManifestsService
  ) { }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
  }

  downloadPDF(manifest) {
    for (const pdf of manifest.manifests) {
      const linkSource = `data:application/pdf;base64,${pdf.manifestData}`;
      const downloadLink = document.createElement('a');
      const fileName = `${pdf.manifestId}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
}
