<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 style="margin: 1rem 0">Log in</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <form class="col-sm-4" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <input
            formControlName="username"
            class="col-sm-12 login-input"
            type="text"
            placeholder="Username">
        </div>
        <div class="row">
          <input
            formControlName="password"
            class="col-sm-9 login-input"
            [type]="showPassword ? 'text' : 'password'"
            placeholder="Password">
          <button type="button" (click)="showPassword = !showPassword" class="col-sm-3 btn-show">
            {{showPassword ? 'HIDE' : 'SHOW'}}
          </button>
        </div>
        <div class="text-danger">{{ (errorAutenticationMessage$ | async)?.message }}</div>

        <div class="row">
          <a class="forgot-password-link" routerLink="/forgot-password">Forgot password?</a>
        </div>

        <div class="row">
          <button type="submit" class="col-sm-12 btn btn-submit">Log in</button>
        </div>
      </form>
      <div *ngIf="notAuth" class="col-sm-12 text-center">
        <p class="error-message">Username or password incorrect</p>
      </div>
    </div>
  </section>
</div>
