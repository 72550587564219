import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import * as fromServices from '@app/services';
import { ActionService, PaymentService } from '@app/services';
import { AlertService } from '@app/shared/components/alerts/alert.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription as SubscriptionObservable } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  displayResults = false;
  submitted = false;

  autoCompleteResult: {
    addresses?: any[],
    customers?: any[],
    shipments?: any[],
    subscriptions?: any[],
  };

  searchControl: FormControl;
  searching = false;

  searchObs: SubscriptionObservable;

  constructor(
    private ref: ElementRef,
    private customerService: fromServices.CustomerService,
    private subscriptionService: fromServices.SubscriptionService,
    private shipmentsService: fromServices.ShipmentService,
    private planService: fromServices.PlanService,
    private alertService: AlertService,
    private actionService: ActionService,
    private paymentService: PaymentService,
    private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.searchControl = new FormControl();
    this.searchControl.valueChanges.pipe(
      tap(() => {
        this.submitted = false;
      }),
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (!this.submitted && value.length > 2) {
        this.autoCompleteSearch(value);
      }
    });
  }

  autoCompleteSearch(value) {
    if (this.searching && this.searchObs) {
      this.searchObs.unsubscribe();
    }

    this.searching = true;
    this.submitted = false;


    this.searchObs = this.dashboardService.fetchAutoComplete(value).subscribe((result) => {
      this.autoCompleteResult = result;

      this.displayResults = true;
      this.searching = false;
    }, () => {
      this.displayResults = false;
      this.searching = false;
    });
  }

  selectAddress(address) {
    this.search.emit({ users: address.users });
    this.displayResults = false;
  }

  selectSubscription(subscription) {
    this.search.emit({ users: subscription.users });
    this.displayResults = false;
  }

  selectCustomer(customer) {
    const users = [customer._id];
    this.search.emit({ users });
    this.displayResults = false;
  }

  selectShipment(shipment) {
    this.search.emit({ users: shipment.users });
    this.displayResults = false;
  }

  onSubmit() {
    this.submitted = true;
    this.displayResults = false;
    this.searching = false;
    this.autoCompleteResult = undefined;
    this.search.emit({ searchValue: this.searchControl.value });
  }

  @HostListener('document:click', ['$event'])
  hideResults($event) {
    if (!this.ref.nativeElement.contains($event.target)) {
      this.displayResults = false;
    } else if (this.autoCompleteResult && this.searchControl.value.length > 2) {
      this.displayResults = true;
    }
  }
}
