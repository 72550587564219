import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

// Models

@Injectable()
export class RateService {
  private apiUrl = environment.apiUrl + '/api';
  private apiUrlV2 = environment.apiUrl + '/api-v2';

  constructor(private http: HttpClient) {
  }

  public getStates(): Observable<any> {
    return this.http.get(`${this.apiUrlV2}/preferred-shipper/states`);
  }

  public savePreferredShipper(data): Observable<any> {
    return this.http.put(`${this.apiUrlV2}/preferred-shipper`, data);
  }


}
