import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { map, delay, switchMap, take } from 'rxjs/operators';
// Models
import { Subscription } from '../models/subscription.model';
import { Address } from '../models/address.model';
// Services
import { ShipmentService } from './shipment.service';
import { Shipment } from '@app/models/shipment.model';
export class SubscriptionService {
    constructor(http, shipmentsService) {
        this.http = http;
        this.shipmentsService = shipmentsService;
        this.apiUrl = environment.apiUrl + '/api';
        this.subscriptionsState = [];
        this.subscriptionsSubject = new BehaviorSubject([]);
    }
    // TODO: change the name of the getSubscriptions to getSubscriptionState
    getSubscriptions() {
        return this.subscriptionsSubject;
    }
    updateSubscriptionsState(data) {
        this.subscriptions = data;
    }
    set subscriptions(data) {
        this.subscriptionsState = data;
        this.subscriptionsSubject.next(data);
    }
    get subscriptions() {
        return this.subscriptionsState.slice();
    }
    cancelSubscription(id, reasons) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
        return this.http.request('delete', `${this.apiUrl}/admin/subscriptions/${id}`, { body: { reasons }, params: httpParams })
            .pipe(map((response) => {
            const updatedSubscription = new Subscription(response.subscription);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    skipSubscription(subscriptionId) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('admin', '5a680f4a4e8ac8bab59f2144');
        return this.http.request('post', `${this.apiUrl}/admin/subscriptions/skipMonth`, {
            body: { subscriptionId, totalMonths: 1 },
            params: httpParams
        })
            .pipe(map((response) => {
            const updatedSubscription = new Subscription(response.subscription);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    editSubscription(data) {
        return this.http.request('put', `${this.apiUrl}/admin/subscriptions/${data.subscriptionId}`, {
            body: data
        }).pipe(map((response) => {
            const updatedSubscription = new Subscription(response.data);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    fetchSubscriptionsRates(subscriptionId) {
        return this.http.get(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/rates`)
            .pipe(map((response) => response.data));
    }
    updateSubscriptionAddress({ subscription, address }) {
        const subscriptionObj = Object.assign({}, subscription, { _id: subscription.id });
        return this.http
            .put(`${this.apiUrl}/admin/subscriptions/${subscription.id}/address`, { subscription: subscriptionObj, address })
            .pipe(map((data) => {
            const updatedSubscription = new Subscription(data.subscription);
            const updatedAddress = new Address(data.address);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return {
                subscription: updatedSubscription,
                address: updatedAddress,
            };
        }));
    }
    switchAutoRenew(data) {
        return this.http
            .put(`${this.apiUrl}/users/${data.userId}/subscriptions/${data.subscriptionId}/${data.autoRenew ? 'turn-on' : 'turn-off'}`, {
            nextBillingDate: data.nextBillingDate,
            turnedOffReasons: data.reasons
        })
            .pipe(delay(1000), map((results) => {
            const updatedSubscription = new Subscription(results.subscription);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    processSubscriptionBilling(data) {
        return this.http
            .post(`${this.apiUrl}/users/${data.customerId}/subscriptions/${data.subscriptionId}/process-subscription-billing`, {
            options: data,
            origin: 'ops'
        })
            .pipe(delay(1000), switchMap(() => this.shipmentsService.getShipmentsState().pipe(take(1)), (result, shipments) => [result, shipments]), map(([result, shipments]) => {
            const updatedSubscription = new Subscription(result.subscription);
            const updatedShipments = result.shipments.map(item => new Shipment(item));
            this.shipmentsService.updateShipmentsState(this.shipmentsService.mergeShipments(shipments, updatedShipments));
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    updatePreferredShipper(_a) {
        var { subscriptionId } = _a, data = tslib_1.__rest(_a, ["subscriptionId"]);
        return this.http
            .post(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/change-preferred-shipper`, Object.assign({}, data))
            .pipe(delay(1000), map((result) => {
            const updatedSubscription = new Subscription(result.subscription);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    clearDeclineStatus(subscriptionId) {
        return this.http
            .put(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/clear-decline-status`, {})
            .pipe(map((result) => {
            const updatedSubscription = new Subscription(result.data.subscription);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    setSubscriptionDiscount(subscriptionId, amount) {
        return this.http.post(`${this.apiUrl}/admin/subscriptions/${subscriptionId}/subscription-discount`, { amount })
            .pipe(map((result) => {
            const updatedSubscription = new Subscription(result);
            this.subscriptions = this.mergeSubscriptions(this.subscriptions, [updatedSubscription]);
            return updatedSubscription;
        }));
    }
    // UTILS
    mergeSubscriptions(oldSubscriptions, newSubscriptions) {
        if (oldSubscriptions.length === 0) {
            return newSubscriptions;
        }
        return newSubscriptions.reduce((allSubscription, newSubscription) => {
            const temp = 'length' in allSubscription ? allSubscription : [];
            const subscriptionIndex = temp.findIndex((subscription) => subscription.id === newSubscription.id);
            if (subscriptionIndex === -1) {
                return [...temp, newSubscription];
            }
            else {
                return [
                    ...temp.slice(0, subscriptionIndex),
                    newSubscription,
                    ...temp.slice(subscriptionIndex + 1)
                ];
            }
        }, oldSubscriptions);
    }
}
