import { Action } from '@ngrx/store';

// models
import { Admin } from './../../models/admin.model';

export const LOAD = '[Admin] Load';
export const LOAD_COMPLETE = '[Admin] Load Complete';
export const LOAD_FAIL = '[Admin] Load Fail';

export const LOGIN = '[Admin] Login';
export const LOGIN_COMPLETE = '[Admin] Login Complete';
export const LOGIN_FAIL = '[Admin] Login Fail';

export const LOGOUT = '[Admin] Logout';
export const LOGOUT_COMPLETE = '[Admin] Logout Complete';
export const LOGOUT_FAIL = '[Admin] Logout Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
  constructor() { }
};

export class LoadCompleteAction implements Action {
  readonly type = LOAD_COMPLETE;
  constructor(public payload: Admin) { }
};

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
  constructor(public payload: any) { }
};

export class LoginAction implements Action {
  readonly type = LOGIN;
  constructor(public payload: { email: string, password: string }) { }
};

export class LoginCompleteAction implements Action {
  readonly type = LOGIN_COMPLETE;
  constructor(public payload: Admin) { }
};

export class LoginFailAction implements Action {
  readonly type = LOGIN_FAIL;
  constructor(public payload: any) { }
};


export class LogoutAction implements Action {
  readonly type = LOGOUT;
  constructor() { }
};

export class LogoutCompleteAction implements Action {
  readonly type = LOGOUT_COMPLETE;
  constructor() { }
};

export class LogoutFailAction implements Action {
  readonly type = LOGOUT_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadAction
  | LoadCompleteAction
  | LoadFailAction
  | LoginAction
  | LoginCompleteAction
  | LoginFailAction
  | LogoutAction
  | LogoutCompleteAction
  | LogoutFailAction;
