import { Update } from '@ngrx/entity/src/models';

import { Action } from '@ngrx/store';
import { Address } from './../../models/address.model';

export const LOAD_CUSTOMER_ADDRESSES = '[Address] Load Customer Addresses';
export const LOAD_CUSTOMER_ADDRESSES_COMPLETE = '[Address] Load Customer Addresses Complete';
export const LOAD_CUSTOMER_ADDRESSES_FAIL = '[Address] Load Customer Addresses Fail';

export const LOAD_CUSTOMERS_ADDRESSES = '[Address] Load Customers Addresses';
export const LOAD_CUSTOMERS_ADDRESSES_COMPLETE = '[Address] Load Customers Addresses Complete';
export const LOAD_CUSTOMERS_ADDRESSES_FAIL = '[Address] Load Customers Addresses Fail';

export const ADD_ADDRESS = '[Address] Add Address';
export const ADD_ADDRESS_COMPLETE = '[Address] Add Address Complete';
export const ADD_ADDRESS_FAIL = '[Address] Add Address Fail';

export const UPDATE_ADDRESS = '[Address] Update Address';
export const UPDATE_ADDRESS_COMPLETE = '[Address] Update Address Complete';
export const UPDATE_ADDRESS_FAIL = '[Address] Update Address Fail';

export class LoadCustomerAddressesAction implements Action {
  readonly type = LOAD_CUSTOMER_ADDRESSES;
  constructor(public payload: { id: string }) { }
};

export class LoadCustomerAddressesCompleteAction implements Action {
  readonly type = LOAD_CUSTOMER_ADDRESSES_COMPLETE;
  constructor(public payload: Address[]) { }
};

export class LoadCustomerAddressesFailAction implements Action {
  readonly type = LOAD_CUSTOMER_ADDRESSES_FAIL;
  constructor(public payload: any) { }
};

export class LoadCustomersAddressesAction implements Action {
  readonly type = LOAD_CUSTOMERS_ADDRESSES;
  constructor(public payload: { ids: string[] }) { }
};

export class LoadCustomersAddressesCompleteAction implements Action {
  readonly type = LOAD_CUSTOMERS_ADDRESSES_COMPLETE;
  constructor(public payload: Address[]) { }
};

export class LoadCustomersAddressesFailAction implements Action {
  readonly type = LOAD_CUSTOMERS_ADDRESSES_FAIL;
  constructor(public payload: any) { }
};

export class AddAddressAction implements Action {
  readonly type = ADD_ADDRESS;
  constructor(public payload: { ids: string[] }) { }
};

export class AddAddressCompleteAction implements Action {
  readonly type = ADD_ADDRESS_COMPLETE;
  constructor(public payload: Address) { }
};

export class AddAddressFailAction implements Action {
  readonly type = ADD_ADDRESS_FAIL;
  constructor(public payload: any) { }
};

export class UpdateAddressAction implements Action {
  readonly type = UPDATE_ADDRESS;
  constructor(public payload: { ids: string[] }) { }
};

export class UpdateAddressCompleteAction implements Action {
  readonly type = UPDATE_ADDRESS_COMPLETE;
  constructor(public payload: Update<Address>) { }
};

export class UpdateAddressFailAction implements Action {
  readonly type = UPDATE_ADDRESS_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadCustomerAddressesAction
  | LoadCustomerAddressesCompleteAction
  | LoadCustomerAddressesFailAction
  | LoadCustomersAddressesAction
  | LoadCustomersAddressesCompleteAction
  | LoadCustomersAddressesFailAction
  | AddAddressCompleteAction
  | UpdateAddressCompleteAction;
