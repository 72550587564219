import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
// store
import { Store } from '@ngrx/store';
import { LogoutAction } from '@app/store/actions/admin.actions';

import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authService = this.injector.get(AuthService);

    if (!authService.token) {
      return next.handle(req.clone())
        .pipe(catchError(error => this.catch401and403Error(error)));
    }
    // Get the auth header from the service.
    const authHeader = `Bearer ${authService.token}`;
    // Clone the request to add the new header.
    const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
    // Pass on the cloned request instead of the original request.
    return next.handle(authReq)
      .pipe(catchError(error => this.catch401and403Error(error)));
  }

  catch401and403Error(error) {
    if ((error.status === 401 || error.status === 403) && !this.router.url.includes('/login')) {
      const extras: NavigationExtras = {
        queryParams: {
          redirectUrl: this.router.url,
          reason: 'logout'
        }
      };
      this.router.navigate(['/login'], extras)
        .then(() => this.store.dispatch(new LogoutAction()));
      return _throw(error);
    } else {
      return _throw(error);
    }
  }
}
