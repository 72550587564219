import { PaymentMethod } from '@app/models/payment-method.model';
import { Shipment } from '@app/models/shipment.model';

export class Referral {
  id: string;
  user: string | Customer;
  name: string;
  redeemed: boolean;
  referralPromotion: string;
  createdAt: Date;
  updatedAt: Date;
  redeemedAt: Date;
  constructor(data) {
    this.id = data._id;
    this.user = data.user;
    this.name = data.name;
    this.redeemed = data.redeemed;
    this.referralPromotion = data.referralPromotion;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.redeemedAt = data.redeemedAt;
  }
}

export class Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  referrals: Referral[];
  createdAt: Date;
  updatedAt: Date;
  // TODO: DELETE THIS AND CREATE A REDUX STORE FOR ADDRESS, THIS IS A TEMP FIX
  addresses: CustomerAddresses[];
  paymentMethods: PaymentMethod[];
  shipments?: Shipment[];
  IP?: string;
  utmData?: string;

  constructor(data) {
    this.id = data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.phone = data.phone;
    this.paymentMethods = data.paymentMethods.map(paymentMethod => new PaymentMethod(paymentMethod));
    this.referrals = data.referrals.map(referral => new Referral(referral));
    this.IP = data.IP;
    this.utmData = data.utmData;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

// TODO: DELETE THIS AND CREATE A REDUX STORE FOR ADDRESS, THIS IS A TEMP FIX
export class CustomerAddresses {
  id: string;
  shippingAddress: string;
  shippingAddressExtra: string;
  line: string;
  formattedLine: string;
  state: string;
  stateCode: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
  label: string;
  // TODO: DELETE THIS AND CREATE A REDUX STORE FOR ADDRESS, THIS IS A TEMP FIX
  subscriptionId: number;

  constructor(data) {
    this.id = data._id;
    this.shippingAddress = data.shippingAddress;
    this.shippingAddressExtra = data.shippingAddressExtra;
    this.line = data.line;
    this.formattedLine = data.formattedLine;
    this.state = data.state;
    this.stateCode = data.stateCode;
    this.city = data.city;
    this.zip = data.zip;
    this.country = data.country;
    this.countryCode = data.countryCode;
    this.label = data.label;
    this.subscriptionId = data.subscriptionId;
  }
}


