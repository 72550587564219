import { Routes } from '@angular/router';
import { ShipmentsLabelsComponent } from '@app/shipments-labels/shipments-labels.component';
import { ShipmentsCreatorComponent } from '@app/shipments-creator/shipments-creator.component';
import { LoginComponent } from '@app/login/login.component';
import { AuthGuard } from '@app/guards/auth/auth.guard';
import { ForgotPasswordComponent } from '@app/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@app/reset-password/reset-password.component';
import { RecoveryPasswordGuard } from '@app/guards/auth/recovery-password.guard';
const ɵ0 = () => import("./dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./tokens-manager/tokens-manager.module.ngfactory").then(m => m.TokensManagerModuleNgFactory), ɵ2 = () => import("./ahoy-skippers/ahoy-skippers.module.ngfactory").then(m => m.AhoySkippersModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0,
        pathMatch: 'full',
    },
    { path: 'shipment-labels', component: ShipmentsLabelsComponent, canActivate: [AuthGuard] },
    { path: 'shipment-creator', component: ShipmentsCreatorComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'recovery-password', component: ResetPasswordComponent, canActivate: [RecoveryPasswordGuard] },
    {
        path: 'coupons-manager',
        loadChildren: 'app/coupons-manager/coupons-manager.module#CouponsManagerModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'rates-manager',
        loadChildren: 'app/rates-manager/rates-manager.module#RatesManagerModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'tokens-manager',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    {
        path: 'ahoy-skippers',
        loadChildren: ɵ2,
        canActivate: [AuthGuard]
    },
    { path: '**', redirectTo: '' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
