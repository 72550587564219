export class Plan {
  id: string;
  price: number;
  name: string;
  title: string;
  status: string;
  shipmentsAtCheckout: number;
  term: number | string;
  type: string;

  constructor(data) {
    this.id = data._id;
    this.price = data.price;
    this.name = data.name;
    this.title = data.title;
    this.status = data.status;
    this.shipmentsAtCheckout = data.shipmentsAtCheckout;
    this.term = data.term;
    this.type = data.type;
  }
}
