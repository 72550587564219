export class Note {
  id: string;
  user: string;
  subscription: string;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  constructor(data) {
    this.id = data._id;
    this.user = data.user;
    this.subscription = data.subscription;
    this.message = data.message;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
