import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AlertModel } from './alert.model';

@Injectable()
export class AlertService {
  constructor() { }

  private alertsStore: AlertModel[] = [];
  public alertsStore$: BehaviorSubject<AlertModel[]> = new BehaviorSubject([]);

  public get alerts(): AlertModel[] {
    return this.alertsStore.slice();
  }

  public set alerts(alerts: AlertModel[]) {
    this.alertsStore = alerts;
    this.alertsStore$.next(alerts);
  }

  private createNewAlert(alert: AlertModel) {
    this.alerts = [...this.alertsStore, alert];
    if (alert.timeout) {
      setTimeout(() => {
        const index = this.alertsStore.findIndex(a => a.id === alert.id);
        this.alerts = [
          ...this.alertsStore.slice(0, index),
          ...this.alertsStore.slice(index + 1)
        ];
      }, alert.timeout);
    }
  }

  public removeAlert(alertId: string) {
    this.alerts = this.alerts.filter(a => a.id !== alertId)
  }

  public success(data: alert) {
    this.createNewAlert(new AlertModel({ type: 'success', ...data }));
  }
  
  public danger(data: alert) {
    this.createNewAlert(new AlertModel({ type: 'danger', ...data }));
  }
}

export interface alert {
  alertsCode: string;
  title: string;
  message: string;
  timeout?: number;
}
