<button (click)="showModal$.next(true)" class="trigger pointer button-animation">
  Builds list
</button>

<app-modal title="Today's builds"
  [show]="showModal$ | async"
  (atCloseBtn)="showModal$.next(false)"
  primaryBtn="Close"
  (atPrimaryBtn)="showModal$.next(false)">

  <ng-container *ngIf="loadingBuilds$ | async">
    Loading...
  </ng-container>

  <ng-container *ngIf="!(loadingBuilds$ | async)">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Download link</th>
          <th scope="col">Created At</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let build of (builds$ | async)">
          <td>{{ build?.fileName }}</td>
          <td class="table-row__item--hide pointer">
            <a [href]="build?.downloadLink">Download</a>
          </td>
          <td>{{ build?.date | date:'short' }}</td>
        </tr>
      </tbody>
    </table>

  </ng-container>

</app-modal>
