import { Injectable } from '@angular/core';
// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
// models
import { Payment } from '@app/models/payment.model';

@Injectable()
export class PaymentService {

  private paymentsState: Payment[] = [];
  private paymentsState$: BehaviorSubject<Payment[]> = new BehaviorSubject([]);

  private get payments(): Payment[] {
    return this.paymentsState.slice();
  }

  private set payments(data: Payment[]) {
    this.paymentsState = data;
    this.paymentsState$.next(data);
  }

  public getPaymentsState(): Observable<Payment[]> {
    console.log('PaymentService::getPaymentsState');
    return this.paymentsState$;
  }

  public updatePaymentsState(data: Payment[]) {
    console.log('PaymentService::updatePaymentsState');
    this.payments = data;
  }

}
