import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Note } from './../../models/note.model';
import * as notesActions from './../../store/actions/notes.actions';

export interface Entities {
  [id: string]: Note;
}

export interface State extends EntityState<Note> {
  entities: Entities;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<Note> = createEntityAdapter<Note>({
  selectId: (note: Note) => note.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export function reducer(
  state: State = initialState,
  action: notesActions.Actions): State {
  switch (action.type) {

    case notesActions.LOAD_SUBSCRIPTION_NOTES:
    case notesActions.CREATE_NOTE: {
      return { ...state, loading: true, loaded: false };
    }

    case notesActions.LOAD_SUBSCRIPTION_NOTES_COMPLETE: {
      return adapter.addAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });
    }

    case notesActions.CREATE_NOTE_COMPLETE: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      });
    }

    case notesActions.LOAD_SUBSCRIPTION_NOTES_FAIL:
    case notesActions.CREATE_NOTE_FAIL: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getNotes = (state: State) => state.entities;
export const getNotesLoaded = (state: State) => state.loaded;
export const getNotesLoading = (state: State) => state.loading;
export const getNotesError = (state: State) => state.error;
