import {
  HttpClient,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import * as jwt_decode from 'jwt-decode';
import { isAfter } from 'date-fns';

import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
  }

  get tokenPayload() {
    const token = this.localStorage.retrieve('authToken');
    if (token) {
      return jwt_decode(token);
    }
    return null;
  }

  get token() {
    const token = this.localStorage.retrieve('authToken');
    if (token) {
      return token;
    }
    return null;
  }

  get isTokenExpired() {
    if (this.tokenPayload) {
      return isAfter(new Date(), this.tokenPayload.exp);
    }
    return true;
  }

  profile(): Observable<HttpResponse<Object>> {
    const userId = this.tokenPayload._id;
    return this.http
      .post(`${this.apiUrl}/users/${userId}/profile`, {}, { observe: 'response' })
      .pipe(map((e: HttpResponse<any>) => this.interceptAuthorizationHeader(e)));
  }

  loginUser({ email, password }): Observable<HttpResponse<Object>> {
    return this.http
      .post(`${this.apiUrl}/users/login`, { email, password }, { observe: 'response' })
      .pipe(map((e: HttpResponse<any>) => this.interceptAuthorizationHeader(e)));
  }

  checkToken(token: string): Observable<string> {
    return this.http
      .post(`${this.apiUrl}/users/checkToken`, { token })
      .pipe(map((data: HttpResponse<any>) => data.body.token));
  }

  refreshToken(token: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(`${this.apiUrl}/users/token`, {}, { observe: 'response' })
      .pipe(map((e: HttpResponse<any>) => this.interceptAuthorizationHeader(e)));
  }

  logout() {
    this.localStorage.clear('authToken');
    // this.router.navigate(['/']);
  }

  setJWTToken(token: string) {
    this.localStorage.store('authToken', token);
  }

  removeJWTToken() {
    this.localStorage.clear('authToken');
  }

  interceptAuthorizationHeader(event: HttpResponse<any>) {
    const token = event.headers.get('authorization').split(' ')[1];
    this.localStorage.store('authToken', token);
    return event;
  }

  forgotPassword(email): Observable<string> {
    return this.http
      .post(`${this.apiUrl}/users/forgot-password`, { email })
      .pipe(map((data: any) => {
        return data.message;
      }));
  }

  recoveryPassword(token: string, password: string, confirmPassword: string): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/users/recover-password`, { token, password, confirmPassword })
      .pipe(map((data: any) => {
        return data.message;
      }));
  }

}
