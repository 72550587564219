import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SocketIoService } from '@app/core/services/socket-io.service';
import { StatsService } from '@app/dashboard/services/stats.service';
import { Retention } from '@app/dashboard/models/retentions.model';
import { Action } from '@app/models/action.model';

@Component({
  selector: 'app-retention',
  templateUrl: './retention.component.html',
  styleUrls: ['./retention.component.scss']
})
export class RetentionComponent implements OnInit, OnDestroy {
  public lastDayStats: LastDayStats = {
    totalTurnedOn: 0,
    totalTurnedOff: 0,
  };
  public todayStats: TodayStats = {
    totalTurnedOn: 0,
    totalTurnedOff: 0,
    offByDp: 0,
    offByUser: 0,
  };
  public retentions: Retention[] = [];

  private autoRenewStats$: Subscription;
  private retentionStats$: Subscription;

  constructor(
    private socketIo: SocketIoService,
    private statsService: StatsService
  ) { }

  ngOnInit() {
    this.autoRenewStats$ = this.socketIo.listen('SUBSCRIPTION--STATS_CHANGED')
      .subscribe(({ totalTurnedOn, totalTurnedOff, offByDp, offByUser }) => {
        this.todayStats = { totalTurnedOn, totalTurnedOff, offByDp, offByUser };
      });

    this.retentionStats$ = this.socketIo.listen('RETENTION--NEW_CREATED')
      .subscribe((retentions: Retention[]) => {
        this.retentions = [...retentions, ...this.retentions].slice(0, 15);
      });

    this.socketIo.emit('SUBSCRIPTION--STATS');

    this.getLatestStats();
  }

  private getLatestStats() {
    this.statsService.fetchLatestRetentions()
      .subscribe(retentions => this.retentions = retentions);

    this.statsService.fetchYesterdayDailyStats()
      .subscribe(data => {
        this.lastDayStats = {
          totalTurnedOn: data.totalSubscriptionsWithAutoRenewON,
          totalTurnedOff: data.totalSubscriptionsWithAutoRenewOFF,
        };
      });
  }

  getActionDetails(action: Action): string {
    const { details } = action;
    if (typeof details === 'undefined') {
      return '';
    }

    switch (action.value) {
      case 'TURN-ON_SUBSCRIPTION':
        return `Next billing date selected: ${action.details.nextBillingDateSelected}`;

      case 'TURN-OFF_SUBSCRIPTION':
      case 'CANCEL_SUBSCRIPTION':
        return action.details.reasons.reduce((all, current, i) =>
          `${all}${i > 0 ? ', ' : ' '}${current.name === 'Other' ? current.name + ': ' + current.value : current.name}`
        , '');

      default:
        console.warn('Can \'t handle the details of this action', action);
        return '';
    }

  }

  numberWithSignal(number) {
    return (number > 0) ? `+${number}` : number.toString();
  }

  ngOnDestroy() {
    this.autoRenewStats$.unsubscribe();
  }

}

export interface LastDayStats {
  totalTurnedOn: number;
  totalTurnedOff: number;
}

export interface TodayStats {
  totalTurnedOn: number;
  totalTurnedOff: number;
  offByDp: number;
  offByUser: number;
}
