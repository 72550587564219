import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as addressActions from '@app/store/actions/address.actions';
// models
// services
import { AddressService } from '@app/services';

@Injectable()
export class AddressEffects {

  @Effect({ dispatch: false }) loadCustomerAddresses$ = this.actions$
    .pipe(ofType(addressActions.LOAD_CUSTOMER_ADDRESSES))
    .pipe(
      map((action: addressActions.LoadCustomerAddressesAction) => action.payload),
      switchMap((data) => {
        return this.addressService
          .getCustomerAddresses({ user: data.id })
          .pipe(
            map(addresses => this.store.dispatch(new addressActions.LoadCustomerAddressesCompleteAction(addresses))),
            catchError(reason => {
              this.store.dispatch(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
              return of(new addressActions.LoadCustomerAddressesFailAction(reason.errors));
            })
          );
      })
    );

  @Effect({ dispatch: false }) loadByCustomerIds$ = this.actions$
    .pipe(ofType(addressActions.LOAD_CUSTOMERS_ADDRESSES))
    .pipe(
      map((action: addressActions.LoadCustomersAddressesAction) => action.payload),
      switchMap(({ ids }) => {
        return this.addressService
          .getAddressesByUserIds({ ids })
          .pipe(
            map(addresses => this.store.dispatch(new addressActions.LoadCustomersAddressesCompleteAction(addresses))),
            catchError(reason => {
              this.store.dispatch(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
              return of(new addressActions.LoadCustomersAddressesFailAction(reason.errors));
            })
          );
      })
    );

  constructor(
    private addressService: AddressService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}

