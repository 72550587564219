import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ShipmentService } from '@app/services';
import * as ShippingCreator from '@config/shipping-creator.json';

@Component({
  selector: 'app-shipments-creator',
  templateUrl: './shipments-creator.component.html',
  styleUrls: ['./shipments-creator.component.scss']
})
export class ShipmentsCreatorComponent implements OnInit {
  shippingCreatorData = (ShippingCreator as any).default;

  public SCForm: FormGroup;
  public creatingShipment = false;
  public fetchingShipments = false;
  public customShipments: any[] = [];
  public currentPage = 0;
  public allCustomShipmentsLoaded = false;
  public showModal = false;
  public rates:any[] = [];
  public rateServices: any[] = [];
  public carriers: string[] = [];
  constructor(
    private el: ElementRef,
    private shipmentService: ShipmentService) { }

  ngOnInit() {
    this.el.nativeElement.classList.add('container-fluid');
    this.createForm();
    this.getCustomShipments(this.currentPage);
    console.log(this.shippingCreatorData);
    this.SCForm.get('rate.carrier').valueChanges.subscribe((carrier) => {
      this.rateServices = this.rates.filter(r => r.carrier === carrier);
      this.SCForm.get('rate.id').setValue(this.rateServices[0].id)
    })
  }
  createForm() {
    this.SCForm = new FormGroup({
      shipmentId: new FormControl(''),
        address_to: new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        shippingAddress: new FormControl(''),
        shippingAddressExtra: new FormControl(''),
        city: new FormControl(''),
        stateCode: new FormControl(''),
        zip: new FormControl(''),
        email: new FormControl(''),
        phone: new FormControl(''),
      }),
      address_from: new FormGroup({
        name: new FormControl(this.shippingCreatorData.defaultCompanyAddress.name),
        street: new FormControl(this.shippingCreatorData.defaultCompanyAddress.street),
        city: new FormControl(this.shippingCreatorData.defaultCompanyAddress.city),
        state: new FormControl(this.shippingCreatorData.defaultCompanyAddress.state),
        zip: new FormControl(this.shippingCreatorData.defaultCompanyAddress.zip),
        phone: new FormControl(this.shippingCreatorData.defaultCompanyAddress.phone),
      }),
      packageDimensions: new FormGroup({
        length: new FormControl(7.5),
        width: new FormControl(4.5),
        height: new FormControl(4.5),
        weight: new FormControl(6),
      }),
      rate: new FormGroup({
        carrier: new FormControl(''),
        service: new FormControl(''),
        id: new FormControl(''),
      }),
      carrierOptions: new FormControl('default'),
    });
  }

  onCreateShipment() {
    this.creatingShipment = true;
    const address_from = this.SCForm.get('address_from').value;
    const address_to = this.SCForm.get('address_to').value;
    const packageDimensions = this.SCForm.get('packageDimensions').value;
    const user = {
      fullName: this.SCForm.get('address_to').value.firstName + ' ' + this.SCForm.get('address_to').value.lastName,
      email: this.SCForm.get('address_to').value.email,
      phone: this.SCForm.get('address_to').value.phone
    };
    const carrierOptions = this.SCForm.get('carrierOptions').value;
    if (carrierOptions === 'default') {
      this.shipmentService.createCustomShipment({ address_from, address_to, packageDimensions, user })
        .subscribe(data => {
          this.customShipments = [data, ...this.customShipments];
          this.SCForm.get('address_to').reset();
          this.creatingShipment = false;
        }, error => {
          alert(`We couldn't create the label, please check the fields and then try again.`);
          this.creatingShipment = false;
        });
      return;
    }

    this.shipmentService.createShipment({ address_from, address_to, packageDimensions, user })
      .subscribe(data => {
        this.creatingShipment = false;
        this.showModal = true;
        this.rates = data.rates;
        this.carriers = [...new Set(this.rates.map(r => r.carrier))];
        this.SCForm.get('rate.carrier').setValue(this.rates[0].carrier);
        this.SCForm.get('shipmentId').setValue(data.id);
      }, error => {
        alert(`We couldn't create the label, please check the fields and then try again.`);
        this.creatingShipment = false;
      });
  }

  buyShipment() {
    this.shipmentService.buyShipment({
      id: this.SCForm.get('shipmentId').value,
      rate: this.SCForm.get('rate.id').value,
    }).subscribe((data) => {
      if(data.error) {
        alert(`We couldn't create the label, please check the fields and then try again.`);
        return;
      }
      this.SCForm.get('address_to').reset();
      console.log(data);
      this.showModal = false;
      this.customShipments = [data, ...this.customShipments];
    }, error => {
      alert(`We couldn't create the label, please check the fields and then try again.`);
    });
  }

  getCustomShipments(page?: number) {
    this.fetchingShipments = true;
    this.shipmentService.fetchCustomShipmentsFromApi(page)
      .subscribe(data => {
        if (data.length === 0) {
          this.allCustomShipmentsLoaded = true;
        }
        this.customShipments = [...this.customShipments, ...data];
        this.fetchingShipments = false;
      });
    
  }

  loadMoreShipments(): void {
    this.fetchingShipments = true;
    this.currentPage = this.currentPage + 1;
    this.getCustomShipments(this.currentPage);
  }

  duplicateShipment(id: string) {
    this.shipmentService.duplicateCustomShipment(id)
      .subscribe((data) => {
        this.customShipments = [data, ...this.customShipments];
      });
  }

  inputPaste($event) {
    const clipboardData = $event.clipboardData || $event.originalEvent.clipboardData;
    const clipboardValue = clipboardData.getData('text');
    const clipboardValueArray = clipboardValue.split('\n');

    const regexAdditionalAddressInfo = new RegExp('(.+,\\s+(.){2}\\s+(\\d){5}(-\\d{4})?)');

    if (clipboardValueArray.length > 2) {
      try {
        const fullName = clipboardValueArray[0].replace(/\s{2,}/, ' ');
        const firstName = (fullName.split(' ')[0]).trim();
        const lastName = fullName.replace(firstName, '').trim();

        let shippingAddressExtra;
        let additionalAddressInfoArray;

        if (regexAdditionalAddressInfo.test(clipboardValueArray[2])) {

          additionalAddressInfoArray = (clipboardValueArray[2]).split(',');
        } else if (clipboardValueArray.length > 3) {
          shippingAddressExtra = clipboardValueArray[2];
          additionalAddressInfoArray = (clipboardValueArray[3]).split(',');
        }

        const city = additionalAddressInfoArray[0];
        const [stateCode, zip] = additionalAddressInfoArray[1].replace(/\s{2,}/, ' ').trim().split(' ');

        this.SCForm.patchValue({
          address_to: {
            ...this.shippingCreatorData.defaultRecipientData,
            firstName,
            lastName,
            shippingAddress: clipboardValueArray[1],
            shippingAddressExtra,
            city,
            stateCode,
            zip,
          }
        });
        $event.preventDefault();
      } catch (e) {
        console.log('Can\'t parse this address');
        console.log(e);
      }

    }

  }

}
