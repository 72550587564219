/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./search.component";
import * as i5 from "../../../services/customer.service";
import * as i6 from "../../../services/subscription.service";
import * as i7 from "../../../services/shipment.service";
import * as i8 from "../../../services/plan.service";
import * as i9 from "../../../shared/components/alerts/alert.service";
import * as i10 from "../../../services/action.service";
import * as i11 from "../../../services/payment.service";
import * as i12 from "../../services/dashboard.service";
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
export { RenderType_SearchComponent as RenderType_SearchComponent };
function View_SearchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSubscription(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = ((((_v.context.$implicit._id == null) ? null : _v.context.$implicit._id.firstName) + " ") + ((_v.context.$implicit._id == null) ? null : _v.context.$implicit._id.lastName)); _ck(_v, 2, 0, currVal_0); }); }
function View_SearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Subscriptions "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-group list-group-flush results-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteResult.subscriptions; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SearchComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCustomer(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Name: ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Email: ", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.firstName + " ") + _v.context.$implicit.lastName); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.email; _ck(_v, 4, 0, currVal_1); }); }
function View_SearchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Customers "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-group list-group-flush results-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_5)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteResult.customers; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SearchComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectShipment(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Receiver: ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit._id; _ck(_v, 3, 0, currVal_0); }); }
function View_SearchComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shipments "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-group list-group-flush results-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_7)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteResult.shipments; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SearchComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAddress(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit._id; _ck(_v, 2, 0, currVal_0); }); }
function View_SearchComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Address "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-group list-group-flush results-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_9)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoCompleteResult.addresses; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_6)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_8)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.autoCompleteResult == null) ? null : _co.autoCompleteResult.subscriptions.length); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.autoCompleteResult == null) ? null : _co.autoCompleteResult.customers.length); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.autoCompleteResult.shipments.length; _ck(_v, 7, 0, currVal_2); var currVal_3 = ((_co.autoCompleteResult == null) ? null : _co.autoCompleteResult.addresses.length); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "input", [["class", "form-control"], ["placeholder", "Please write full email or full name."], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 540672, null, 0, i3.FormControlDirective, [[8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_14 = _co.searchControl; _ck(_v, 9, 0, currVal_14); var currVal_15 = _co.displayResults; _ck(_v, 13, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 11).ngClassValid; var currVal_12 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }); }
export function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).hideResults($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 114688, null, 0, i4.SearchComponent, [i1.ElementRef, i5.CustomerService, i6.SubscriptionService, i7.ShipmentService, i8.PlanService, i9.AlertService, i10.ActionService, i11.PaymentService, i12.DashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchComponentNgFactory = i1.ɵccf("app-search", i4.SearchComponent, View_SearchComponent_Host_0, { change: "change" }, { search: "search" }, []);
export { SearchComponentNgFactory as SearchComponentNgFactory };
