import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuildsService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient
  ) { }

  getTodaysBuilds(): Observable<IBuild[]> {
    return this.http.get<{data: IBuild[]}>(`${this.apiUrl}/builds/today`)
      .pipe(pluck('data'));
  }
}

export interface IBuild {
  date: string;
  fileName: string;
  downloadLink: string;
}
