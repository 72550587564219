import { Component, OnInit } from '@angular/core';
import  { utcToZonedTime, format } from 'date-fns-tz';
import { DisputeModalService } from "@app/dashboard/services/dispute-modal.service";

@Component({
  selector: 'app-dispute-modal',
  templateUrl: './dispute-modal.component.html',
  styleUrls: ['./dispute-modal.component.scss']
})
export class DisputeModalComponent implements OnInit {
  open$ = this.disputeModalService.open$.asObservable();
  disputeData$ = this.disputeModalService.disputeData$.asObservable();

  constructor(
    private readonly disputeModalService: DisputeModalService
  ) { }

  ngOnInit() {
    this.disputeData$.subscribe(console.log)
  }

  closeModal(): void {
    this.disputeModalService.setModalStatus(false);
  }

  utcToPST(time: string | Date) {
    const date = new Date(time);
    const timezone = 'America/Los_Angeles';
    const zonedTime = utcToZonedTime(date, timezone);

    return format(zonedTime, 'yyyy-MM-dd');
  }

}
