<div class="row">
  <section class="container">
    <form #SCFormTemplate [formGroup]="SCForm" class="row" novalidate (ngSubmit)="onCreateShipment()">
      <div class="col-sm-6" formGroupName="address_to">
        <div class="row">
          <h4 class="col-sm-12 sc-form__fiel-wrapper__title">To: </h4>
          <input (paste)="inputPaste($event)" class="col-sm-6 sc-form__field" formControlName="firstName"
                 placeholder="First Name"
                 type="text">
          <input (paste)="inputPaste($event)" class="col-sm-6 sc-form__field" formControlName="lastName"
                 placeholder="Last Name"
                 type="text">
          <input (paste)="inputPaste($event)" class="col-sm-12 sc-form__field" formControlName="shippingAddress"
                 placeholder="Street 1"
                 type="text">
          <input (paste)="inputPaste($event)" class="col-sm-12 sc-form__field" formControlName="shippingAddressExtra"
                 placeholder="Street 2" type="text">
          <input (paste)="inputPaste($event)" class="col-sm-4 sc-form__field" formControlName="city" placeholder="City"
                 type="text">
          <input (paste)="inputPaste($event)" class="col-sm-4 sc-form__field" formControlName="stateCode"
                 placeholder="State"
                 type="text">
          <input (paste)="inputPaste($event)" class="col-sm-4 sc-form__field" formControlName="zip" placeholder="ZIP"
                 type="text">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="email" placeholder="Email">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="phone" placeholder="Phone Number">
        </div>
        <!-- <span>{{ SCForm.get('address_to').value | json }}</span> -->
      </div>

      <div class="col-sm-6" formGroupName="address_from">
        <div class="row">
          <h4 class="col-sm-12 sc-form__fiel-wrapper__title">From: </h4>
          <select class="col-sm-12 sc-form__field" formControlName="name" placeholder="name">
            <option
              [value]="shippingCreatorData.defaultCompanyAddress.name">{{ shippingCreatorData.defaultCompanyAddress.name }}</option>
            <option value="Michel Cohen (BEON) via {{ shippingCreatorData.defaultCompanyAddress.name }}">Michel Cohen
              (BEON)
              via {{ shippingCreatorData.defaultCompanyAddress.name }}</option>
            <option value="Damian Wasserman (BEON) via {{ shippingCreatorData.defaultCompanyAddress.name }}">Damian
              Wasserman
              (BEON) via {{ shippingCreatorData.defaultCompanyAddress.name }}</option>
          </select>
          <input class="col-sm-12 sc-form__field" type="text" formControlName="street" placeholder="street 1">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="city" placeholder="city">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="state" placeholder="state">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="zip" placeholder="zip">
          <input class="col-sm-12 sc-form__field" type="text" formControlName="phone" placeholder="phone">
        </div>
        <!-- <span>{{ SCForm.get('address_from').value | json }}</span> -->
      </div>

      <div class="col-sm-6" formGroupName="packageDimensions">
        <div class="row">
          <h4 class="col-sm-12 sc-form__fiel-wrapper__title">Package dimensions: </h4>
          <input class="col-sm-12 sc-form__field" type="number" formControlName="length" placeholder="length">
          <input class="col-sm-12 sc-form__field" type="number" formControlName="width" placeholder="width">
          <input class="col-sm-12 sc-form__field" type="number" formControlName="height" placeholder="height">
          <input class="col-sm-12 sc-form__field" type="number" formControlName="weight" placeholder="weight">
        </div>
        <!-- <span>{{ SCForm.get('packageDimensions').value | json }}</span> -->
      </div>
      <div class="col-sm-6">
        <div class="row">
          <h4 class="col-sm-12 sc-form__fiel-wrapper__title">Carrier Options</h4>
          <select class="col-sm-12 sc-form__field" formControlName="carrierOptions">
            <option value="default">Use Defaults</option>
            <option value="choose">Let me choose</option>
          </select>
        </div>
        <div *ngIf="SCForm.get('carrierOptions').value === 'choose'">
          <div> <i> (Modal should appear after creating shipment) </i> </div>
          <div> <b> Buy the carrier and service after creating the shipment. </b> </div>
        </div>

      </div>

      <div class="col-sm-12 sc-form__button-wrapper">
        <button
          class="createShipmentButton button-animation"
          type="submit"
          [disabled]="creatingShipment"
          [ngClass]="{ 'button-animation--active': creatingShipment }">
          {{ creatingShipment ? 'Creating' : 'Create' }} shipment
        </button>
      </div>
    </form>

    <div class="row">
      <h4 class="col-sm-12 text-left">Custom Shipments: </h4>
    </div>

    <ul class="row list-container">
      <li class="col-sm-12 list-item list-item--header">
        <div class="row">
          <div class="col-sm-4">User's name</div>
          <div class="col">User's address</div>
        </div>
      </li>

      <li class="col-sm-12 list-item" *ngFor="let shipment of customShipments">
        <div class="row">
          <div class="list-item__div col-sm-4">{{ shipment?.fullName }}</div>
          <div class="list-item__div col">{{ shipment?.email }}</div>
          <div class="list-item__div col-sm-auto text-right">
            <a [href]="shipment?.tracker.publicUrl" class="list-item__button"
               target="_blank">{{ shipment?.tracker.trackingCode }}</a>
            <a class="list-item__button" target="_blank" [href]="shipment?.shippingLabel.labelUrl">Label</a>
            <a class="list-item__button" (click)="duplicateShipment(shipment._id)">Reship</a>
          </div>
        </div>
      </li>
    </ul>

    <div class="row justify-content-center">
      <button (click)="loadMoreShipments()"
              [disabled]="allCustomShipmentsLoaded"
              [ngClass]="{ 'button-animation--active': fetchingShipments }"
              class="col-sm-auto btn mt-4 button-animation"
              type="button">
        {{ !allCustomShipmentsLoaded ? 'Load more' : 'No more shipments available' }}
      </button>
    </div>
  </section>
</div>

<app-modal [show]="showModal" (atCloseBtn)="showModal = false" [formGroup]="SCForm">
  <div class="form-group" formGroupName="rate">
    <label>Shipment rates</label>
    <div class="form-group">
      <label for="buildOptionsForm__date">Select Carrier:</label>
      <select
        class="form-control"
        formControlName="carrier"
      >
        <option
          *ngFor="let carrier of carriers"
          [value]="carrier">
          {{ carrier }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="buildOptionsForm__date">Select a Class:</label>
      <select class="form-control" formControlName="id">
        <option
          *ngFor="let service of rateServices"
          [value]="service.id"
        >
          {{ service.service }}
        </option>
      </select>
    </div>
  </div>
  <button class="btn mt-4 btn-margin" (click)="buyShipment()">Buy Shipment</button>
  <button class="btn mt-4 btn-margin" (click)="showModal = false">Close</button>
</app-modal>