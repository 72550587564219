import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Address } from './../../models/address.model';
import * as addressActions from './../../store/actions/address.actions';

export interface Entities {
  [id: string]: Address;
}

export interface State extends EntityState<Address> {
  entities: Entities;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>({
  selectId: (address: Address) => address.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export function reducer(
  state: State = initialState,
  action: addressActions.Actions): State {
  switch (action.type) {

    case addressActions.LOAD_CUSTOMER_ADDRESSES:
    case addressActions.LOAD_CUSTOMERS_ADDRESSES: {
      return { ...state, loading: true, loaded: false };
    }

    case addressActions.LOAD_CUSTOMER_ADDRESSES_COMPLETE:
    case addressActions.LOAD_CUSTOMERS_ADDRESSES_COMPLETE: {
      return adapter.addAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });
    }

    case addressActions.ADD_ADDRESS_COMPLETE: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      });
    }

    case addressActions.UPDATE_ADDRESS_COMPLETE: {
      return adapter.updateOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      });
    }

    case addressActions.LOAD_CUSTOMERS_ADDRESSES_FAIL:
    case addressActions.LOAD_CUSTOMER_ADDRESSES_FAIL: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getAddresses = (state: State) => state.entities;
export const getAddressesLoaded = (state: State) => state.loaded;
export const getAddressesLoading = (state: State) => state.loading;
export const getAddressesError = (state: State) => state.error;
