import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private httpClient: HttpClient
  ) { }

  getReferralPromotions(): Observable<any[]> {
    return this.httpClient
      .get(`${this.apiUrl}/referral-promotions`)
      .pipe(map((result: { referralPromotions: any }) => result.referralPromotions));
  }

}
