import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { get } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ManifestsService {
    constructor(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl + '/api';
        this.apiV2Url = environment.apiUrl + '/api-v2';
    }
    getTodaysManifests() {
        console.log('ManifestsService::getTodaysManifests');
        return this.http.get(`${this.apiV2Url}/manifests`)
            .pipe(map((response) => get(response, 'data', [])));
    }
    generateManifest() {
        console.log('ManifestsService::generateManifest');
        return this.http.post(`${this.apiV2Url}/manifests`, {})
            .pipe(map((response) => response.data));
    }
}
ManifestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManifestsService_Factory() { return new ManifestsService(i0.ɵɵinject(i1.HttpClient)); }, token: ManifestsService, providedIn: "root" });
