import { createSelector } from '@ngrx/store';

import * as fromRoot from './../selectors';
import * as fromNotes from './../../store/reducers/notes.reducer';
import { Note } from './../../models/note.model';

export { State, Entities } from './../reducers/notes.reducer'

export const {
  selectIds: getNotesIds,
  selectEntities: getNotesEntities,
  selectTotal: getTotalANotes,
  selectAll: getAllNotes,
} = fromNotes.adapter.getSelectors(fromRoot.getNotesState);

export const getNotesLoaded = createSelector(
  fromRoot.getNotesState,
  (state: fromNotes.State) => state.loaded
);

export const getNotesLoading = createSelector(
  fromRoot.getNotesState,
  (state: fromNotes.State) => state.loading
);
