
<div *ngIf="customer" [ngClass]="{'show': customer }" [ngStyle]="{'display': customer ? 'block' : 'none',
'backgroundColor': customer ? 'rgba(0,0,0,.8)' : 'transparent' }" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{customer.firstName}} Referrals
        </h5>
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="">
          <li *ngFor="let referral of customer.referrals"> {{referral.name}} - {{referral.user.email}} </li>
        </ul>

        <div class="form-group">
          <label for="newReferral">New Referral email</label>
          <input [(ngModel)]="email" name="newReferral" type="email" class="form-control">
          <div *ngIf="error" class="invalid-feedback">{{error.message}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="addReferral()">
          Add Referral
        </button>
      </div>
    </div>
  </div>
</div>
