export class PaymentMethod {
  cardType: string;
  default: boolean;
  expirationDate: string;
  last4: string;
  maskedNumber: string;
  token: string;
  id: string;

  constructor(data) {
    this.id = data._id;
    this.cardType = data.cardType;
    this.default = data.default;
    this.expirationDate = data.expirationDate;
    this.last4 = data.last4;
    this.maskedNumber = data.maskedNumber;
    this.token = data.token;
  }
}
