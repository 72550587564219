import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpResponseBase } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

// models
import { Note } from './../models/note.model';
import { map } from 'rxjs/operators';

export interface FetchCustomersParams {
  ids: string[];
  limit: number | undefined;
  page: number | undefined;
}

@Injectable()
export class NotesService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private httpClient: HttpClient
  ) { }

  getSubscriptionNotes({ userId, subscriptionId }): Observable<Note[]> {
    return this.httpClient
      .get(`${this.apiUrl}/users/${userId}/subscriptions/${subscriptionId}/notes/`)
      .pipe(map((result: any) => result.notes.map(notes => new Note(notes))));
  }

  createNote({ userId, subscriptionId, message }): Observable<Note> {
    return this.httpClient
      .post(`${this.apiUrl}/users/${userId}/subscriptions/${subscriptionId}/notes/`, { message })
      .pipe(map((result: any) => new Note(result.note)));
  }
}
