import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { Coupon, GGCoupon } from '@app/models/coupon.model';
import { Observable } from 'rxjs';

interface Filters {
  code: string
  $status?: string
  $skip?: string;
  $limit?: string;
}

@Injectable()
export class CouponsService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient
  ) {

  }
  getGGCouponsList(filter:Filters = {code: '', $status:'', $skip:'0', $limit: '25'}) {
    const params = new HttpParams({ fromObject: { ...filter } });
    return this.http.get(this.apiUrl.concat('/admin/ggcoupons'), {params})
      .pipe(map((data: any) => {
        return {
          coupons: data.coupons.map(c => new GGCoupon(c)),
          pagination: data.pagination,
        }
      }));
  }
  getCouponsList(filter?: string): Observable<Coupon[]> {
    const params = new HttpParams({ fromObject: { code: filter } });
    return this.http.get(this.apiUrl.concat('/admin/coupons'), { params })
      .pipe(map((data: any) => {
        return data.coupons.map(c => new Coupon(c));
      }));
  }

  createCoupon(coupon: Coupon): Observable<Coupon> {
    return this.http.post(this.apiUrl.concat('/admin/coupons'), { coupon })
      .pipe(map((data: any) => new Coupon(data)));
  }

  editCoupon(id: string, coupon: Coupon): Observable<Coupon> {
    return this.http.put(this.apiUrl.concat(`/admin/coupons/${id}`), { coupon })
      .pipe(map((data: any) => new Coupon(data)));
  }

  enableCoupon(id: string): Observable<Coupon> {
    return this.http.put(this.apiUrl.concat(`/admin/coupons/${id}/enable`), {})
      .pipe(map((data: any) => new Coupon(data)));
  }

  disableCoupon(id: string): Observable<Coupon> {
    return this.http.delete(this.apiUrl.concat(`/admin/coupons/${id}/disable`))
      .pipe(map((data: any) => new Coupon(data)));
  }

}
