<div class="row">
  <div class="container">

    <!-- Headers -->
    <div class="row header-title">
      <p class="col-sm-2 offset-sm-1">ON</p>
      <p class="col-sm-1">:</p>
      <p class="col-sm-2 text--light">{{ todayStats.totalTurnedOn }}</p>
      <p class="col-sm-2">{{ numberWithSignal(todayStats.totalTurnedOn - lastDayStats.totalTurnedOn) }}</p>
      <p class="col-sm-3"></p>
    </div>

    <div class="row header-title">
      <p class="col-sm-2 offset-sm-1">OFF</p>
      <p class="col-sm-1">:</p>
      <p class="col-sm-2 text--light">{{ todayStats.totalTurnedOff }}</p>
      <p class="col-sm-2">{{ numberWithSignal(todayStats.totalTurnedOff - lastDayStats.totalTurnedOff) }}</p>
      <p class="col-sm-3">({{ todayStats.offByUser }} / {{ todayStats.offByDp }})</p>
    </div>
    <!-- Headers end -->
      
    <!-- Actions list -->
    <ul class="row action-list">

      <li *ngFor="let r of retentions; let i = index" class="col-sm-12">
        <div class="row">
          <p class="col-sm-7 action-text">
            {{ r.subscription.autoRenew ? 'ON' : 'OFF' }}
            {{ r.createdAt | date: 'M/dd @ hh:mm aa' }} 
            <span class="action-text--bold">{{ r.user?.firstName + ' ' + r.user?.lastName }}</span>
            from {{ r.address?.city + ', ' + r.address.state }}
          </p>

          <p class="col-sm-4 action-text">
             <i>{{ getActionDetails(r) }}</i>
          </p>
        </div>
      </li>
      
    </ul>
    <!-- Actions list end -->
  </div>
</div>