import * as tslib_1 from "tslib";
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as notesActions from './../actions/notes.actions';
// services
import { NotesService } from '@app/services';
import { ofType } from '@ngrx/effects';
export class NotesEffects {
    constructor(notesService, actions$, store) {
        this.notesService = notesService;
        this.actions$ = actions$;
        this.store = store;
        this.loadSubscriptionNotes$ = this.actions$
            .pipe(ofType(notesActions.LOAD_SUBSCRIPTION_NOTES))
            .pipe(map((action) => action.payload), switchMap(({ userId, subscriptionId }) => {
            return this.notesService
                .getSubscriptionNotes({ userId, subscriptionId })
                .pipe(map(notes => this.store.dispatch(new notesActions.LoadSubscriptionNotesCompleteAction(notes))), catchError(reason => {
                this.store.dispatch(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
                return of(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
            }));
        }));
        this.createNote$ = this.actions$
            .pipe(ofType(notesActions.CREATE_NOTE))
            .pipe(map((action) => action.payload), switchMap(({ userId, subscriptionId, message }) => {
            return this.notesService
                .createNote({ userId, subscriptionId, message })
                .pipe(map(note => this.store.dispatch(new notesActions.CreateNoteCompleteAction(note))), catchError(reason => {
                this.store.dispatch(new notesActions.CreateNoteFailAction(reason.errors));
                return of(new notesActions.CreateNoteFailAction(reason.errors));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], NotesEffects.prototype, "loadSubscriptionNotes$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], NotesEffects.prototype, "createNote$", void 0);
