import * as moment from 'moment';

class BaseCoupon {
  id: string;
  code: string;
  bannerMessage: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  true: boolean;
  amount: number;
  constructor(data?) {
    if (data) {
      this.id = data._id;
      this.code = data.code;
      this.bannerMessage = data.bannerMessage;
      this.amount = data.amount;
      this.status = data.status;
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
    }
  }
}

export class Coupon extends BaseCoupon{
  true: boolean;
  amount: number;
  name: string;
  description: string;
  canBeUsedByClient: boolean;
  validUntil: string;
  boxSku: string;
  type: string;
  checkoutItems: any;
  totalRedeemed: number;
  shippingCostsDiscount: number;

  constructor(data?) {
    super(data);
    if (data) {
      this.checkoutItems = data.checkoutItems;
      this.type = data.type;
      this.name = data.name;
      this.description = data.description;
      this.validUntil = data.validUntil && moment(data.validUntil).format('YYYY-MM-DD');
      this.amount = data.amount;
      this.canBeUsedByClient = data.canBeUsedByClient;
      this.boxSku = data.boxSku;
      this.totalRedeemed = data.totalRedeemed;
      this.shippingCostsDiscount = data.shippingCostsDiscount || 0;
    }
  }
}

export class GGCoupon extends BaseCoupon {
  shippingCostsDiscount: Date;
  currentUse: number;
  freeMonths: number;
  maxUse: number;
  usedBySubscriptions: string[];
  allowedPlans: any[];

  constructor(data?) {
    super(data);
    if (data) {
      this.shippingCostsDiscount = this.shippingCostsDiscount;
      this.currentUse = data.currentUse;
      this.freeMonths = data.freeMonths;
      this.maxUse = data.maxUse;
      this.usedBySubscriptions = data.usedBySubscriptions;
      this.allowedPlans = data.allowedPlans;
    }
  }
  isAvailable() {
    return this.currentUse !== this.maxUse;
  }
  planNames() {
    return this.allowedPlans.map(p => p.name);
  }
}
