import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  Validators
} from '@angular/forms';
import { AuthService } from '@app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  serverError;
  displayErrors = false;
  forgotPasswordForm;
  resetPasswordRequested: Boolean;
  messageResponse: string;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {

  }

  onSubmit() {
    this.serverError = null;
    if (this.forgotPasswordForm.invalid) {
      this.displayErrors = true;
      return;
    }

    this.displayErrors = false;

    this.authService.forgotPassword(this.forgotPasswordForm.get('email').value)
      .subscribe(response => {
        this.resetPasswordRequested = true;
        this.messageResponse = response;
      }, err => {
        this.serverError = err.error.message;
      });
  }
}
