import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@env/environment';
// models
import { Address } from './../models/address.model';
import { map } from 'rxjs/operators';

export interface FetchCustomersParams {
  ids: string[];
  limit: number | undefined;
  page: number | undefined;
}

@Injectable()
export class AddressService {
  private apiUrl = environment.apiUrl + '/api';
  private addressState: Address[] = [];
  private addressSubject: BehaviorSubject<Address[]> = new BehaviorSubject([]);

  constructor(
    private httpClient: HttpClient
  ) { }

  private get address(): Address[] {
    return this.addressState.slice();
  }

  private set address(data: Address[]) {
    this.addressState = data;
    this.addressSubject.next(data);
  }

  public getAddressState(): Observable<Address[]> {
    console.log('AddressService::getAddressState');
    return this.addressSubject;
  }

  public updateAddressState(data: Address[]) {
    console.log('AddressService::updateAddressState');
    this.address = data;
  }

  public addAddressItem(address: Address) {
    this.address = [...this.address, address];
  }

  getCustomerAddresses({ user }): Observable<Address[]> {
    return this.httpClient
      .get(`${this.apiUrl}/users/${user}/address`)
      .pipe(map((result: any) => result.addresses.map(address => new Address(address))));
  }

  getAddressesByUserIds({ ids }): Observable<Address[]> {
    return this.httpClient
      .post(`${this.apiUrl}/admin/address/by-customer-ids`, { ids })
      .pipe(map((result: any) => result.addresses.map(address => new Address(address))));
  }

  updateAddress(address): Observable<Address> {
    return this.httpClient
      .post(`${this.apiUrl}/admin/address/${address.id}`, { address })
      .pipe(map((result: any) => new Address(result.address)));
  }
}
