import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';
import { map } from 'rxjs/operators';

// Models
import { Plan } from '@app/models/plan.model';

@Injectable()
export class PlanService {
  private apiUrl = environment.apiUrl + '/api';
  private plansState: Plan[] = [];
  private plansState$: BehaviorSubject<Plan[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
  }

  public getPlansState(): Observable<Plan[]> {
    return this.plansState$;
  }

  public updateSubscriptionsState(data: Plan[]) {
    this.plans = data;
  }

  private set plans(data: Plan[]) {
    this.plansState = data;
    this.plansState$.next(data);
  }

  private get plans(): Plan[] {
    return this.plansState.slice();
  }

  fetchPlans(): Observable<Plan[]> {
    return this.http.get(`${this.apiUrl}/products/plans/`)
      .pipe(
        map((response: any) => {
          const newState = this.mergeState(this.plans, response.plans.map(plan => new Plan(plan)));
          this.updateSubscriptionsState(newState);
          return newState;
        })
      );
  }

  // UTILS
  private mergeState(oldState: Plan[], newState: Plan[]) {
    if (oldState.length === 0) {
      return newState;
    }
    return newState.reduce((all, current) => {
      const temp = 'length' in all ? all : [];
      const index = temp.findIndex((t: Plan) => t.id === current.id);
      if (index === -1) {
        return [...temp, current];
      } else {
        return [
          ...temp.slice(0, index),
          current,
          ...temp.slice(index + 1)
        ];
      }
    }, oldState);
  }
}
