import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as notesActions from './../actions/notes.actions';

// services
import { NotesService } from '@app/services';
import { ofType } from '@ngrx/effects';

@Injectable()
export class NotesEffects {

  @Effect({ dispatch: false }) loadSubscriptionNotes$ = this.actions$
    .pipe(ofType(notesActions.LOAD_SUBSCRIPTION_NOTES))
    .pipe(
      map((action: notesActions.LoadSubscriptionNotesAction) => action.payload),
      switchMap(({ userId, subscriptionId }) => {
        return this.notesService
          .getSubscriptionNotes({ userId, subscriptionId })
          .pipe(
            map(notes => this.store.dispatch(new notesActions.LoadSubscriptionNotesCompleteAction(notes))),
            catchError(reason => {
              this.store.dispatch(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
              return of(new notesActions.LoadSubscriptionNoteFailAction(reason.errors));
            })
          );
      })
    );

  @Effect({ dispatch: false }) createNote$ = this.actions$
    .pipe(ofType(notesActions.CREATE_NOTE))
    .pipe(
      map((action: notesActions.CreateNoteAction) => action.payload),
      switchMap(({ userId, subscriptionId, message }) => {
        return this.notesService
          .createNote({ userId, subscriptionId, message })
          .pipe(
            map(note => this.store.dispatch(new notesActions.CreateNoteCompleteAction(note))),
            catchError(reason => {
              this.store.dispatch(new notesActions.CreateNoteFailAction(reason.errors));
              return of(new notesActions.CreateNoteFailAction(reason.errors));
            })
          );
      })
    );

  constructor(
    private notesService: NotesService,
    private actions$: Actions,
    private store: Store<any>
  ) { }
}

