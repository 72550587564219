import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

// Models

@Injectable()
export class StatsService {
  private apiUrl = environment.apiUrl + '/api';
  // private plansState: Plan[] = [];
  // private plansState$: BehaviorSubject<Plan[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
  }

  /*public getPlansState(): Observable<Plan[]> {
    return this.plansState$;
  }

  public updateSubscriptionsState(data: Plan[]) {
    this.plans = data;
  }

  private set plans(data: Plan[]) {
    this.plansState = data;
    this.plansState$.next(data);
  }

  private get plans(): Plan[] {
    return this.plansState.slice();
  }*/

  public fetchChurn() {
    return this.http.get(`${this.apiUrl}/public/churn/`)
      .pipe(
        map((response: any) => response)
      );
  }

  public fetchSales() {
    return this.http.get(`${this.apiUrl}/public/sales/`)
      .pipe(
        map((response: any) => response)
      );
  }

  public fetchMedia(utmFilter) {
    const params = utmFilter && { utmFilter };
    return this.http.get(`${this.apiUrl}/public/media/report/`, { params });
  }
}
