import { Injectable } from '@angular/core';
import { connect } from 'socket.io-client';
import { environment } from '@env/environment';

import { Observable, Subscriber } from 'rxjs';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class SocketIoService {
  private socket;

  constructor(
    private sessionStorage: SessionStorageService,
  ) {
    const storedUUID = this.sessionStorage.retrieve('uuid');
    this.socket = connect(environment.apiUrl, {
      query: { uuid: storedUUID }
    });

    this.socket.on('connect', () => {
    });
  }

  listen(event: string): Observable<any> {
    const observable = new Observable((observer: Subscriber<any>) => {
      this.socket.on(event, data => observer.next(data));

      // return () => this.socket.disconnect();
    });

    return observable;
  }

  emit(event: string, data?: any) {
    this.socket.emit(event, data);
  }
}
