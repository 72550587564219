import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
// -- Modal
import { ModalComponent } from './components/modal/modal.component';
// -- Alerts
import { BarAlertComponent } from './components/alerts/bar-alert/bar-alert.component';
import { BarAlertItemComponent } from './components/alerts/bar-alert/bar-alert-item/bar-alert-item.component';

const COMPONENTS = [ModalComponent, BarAlertComponent, BarAlertItemComponent];

// Services
// -- Alerts
import { AlertService } from './components/alerts/alert.service';

const SERVICES = [AlertService];

// Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

const PIPES = [SafeHtmlPipe];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    ...SERVICES,
  ],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [
    ...COMPONENTS,
    ...PIPES,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
