import { Address } from './address.model';

export class Shipment {
  loading: boolean;
  id: string;
  userId: string;
  customText: string;
  subscriptionId: number;
  receiver: string | undefined;
  address: string;
  reship: {
    isReship: boolean,
    reshipAt?: Date,
    reshipOf?: string,
  };
  shippingLabel?: {
    id: string,
    labelUrl: string
  };
  tracker?: {
    trackerId: string,
    shipmentId: string,
    trackingCode: string,
    trackingUrl: string,
    status: string,
    status_code: string,
    internalStatus: string,
    refunded: boolean,
  };
  printed?: {
    isPrinted: boolean,
    printedAt: null | string,
    cantPrintUntil: Date,
  };
  selectedBox?: any;
  createdAt: Date;
  updatedAt: Date;
  isGift: boolean;
  message: string;
  cancelled: boolean;

  trackingEvents?: {
    events: Array<{
      status: string;
    }>;
    lastStatusCode: string;
  }

  constructor(data) {
    this.loading = false;
    this.id = data._id;
    this.userId = data.user;
    this.subscriptionId = data.subscription;
    this.receiver = data.receiver;
    this.reship = {
      isReship: data.reship.isReship,
      reshipAt: data.reship.reshipAt ? new Date(data.reship.reshipAt) : undefined,
      reshipOf: data.reship.reshipOf,
    };
    this.address = data.address;
    this.shippingLabel = typeof data.shippingLabel !== 'undefined' ? {
      id: data.shippingLabel._id,
      labelUrl: data.shippingLabel.labelUrl
    } : undefined;

    this.tracker = data.tracker ? {
      trackerId: data.tracker._id,
      shipmentId: data.tracker.shipmentId,
      trackingCode: data.tracker.trackingCode,
      trackingUrl: data.tracker.publicUrl,
      status: data.tracker.status,
      status_code: data.tracker.status_code,
      internalStatus: data.tracker.internalStatus,
      refunded: data.tracker.refunded
    } : undefined;

    this.printed = {
      isPrinted: data.printed.isPrinted,
      printedAt: data.printed.printedAt,
      cantPrintUntil: data.printed.cantPrintUntil,
    };
    this.customText = data.customText;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.isGift = data.isGift;
    this.selectedBox = data.selectedBox;
    this.message = data.message;
    this.cancelled = data.cancelled;

    this.trackingEvents = data.trackingEvents;
  }
}

export class ShipmentStats {
  shipments: number;
  unprintedShipments: number;
  shipmentsWithoutLabels: number;
  monthlyStats: {
    [key: string]: {
      value: number,
      total: number,
      onHold: number
    }
  };

  constructor(data) {
    this.shipments = data.shipments;
    this.unprintedShipments = data.unprintedShipments;
    this.shipmentsWithoutLabels = data.shipmentsWithoutLabels;
    this.monthlyStats = data.monthlyStats;
  }
}

export class ShipmentRate {
  id: string;
  carrier: string;
  carrierId: string;
  service: string;
  rate: string;
  currency: string;
  estDeliveryDays: number;
  deliveryDateGuaranteed: boolean;

  constructor(data) {
    this.id = data.id || data._id;
    this.carrier = data.carrier;
    this.carrierId = data.carrier_account_id;
    this.service = data.service;
    this.rate = data.rate;
    this.currency = data.currency;
    this.estDeliveryDays = data.est_delivery_days;
    this.deliveryDateGuaranteed = data.delivery_date_guaranteed;
  }

  get formatedPrice(): string {
    return `${this.currency} ${this.rate}`;
  }
}
