import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef, ElementRef, ViewRef } from '@angular/core';
import * as client from 'braintree-web/client';
import * as hostedFields from 'braintree-web/hosted-fields';
import { Customer } from '@app/models/customer.model';
import { BraintreeService } from '@app/core/services';
import { CustomerService } from '@app/services';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import { AlertService } from '@app/shared/components/alerts/alert.service';
export class PaymentMethodsModalComponent {
    constructor(alertService, customerService, braintreeService, changeDetector) {
        this.alertService = alertService;
        this.customerService = customerService;
        this.braintreeService = braintreeService;
        this.changeDetector = changeDetector;
        this.dropInInstance = null;
        this.paymentProcessor = environment.paymentProcessor;
        this.canSubmitPayment = false;
        this.isFocus = false;
        this.isFormValid = false;
        this.isClientInitialized = false;
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        this.isNumberTouched = false;
        this.isCvvTouched = false;
        this.isExpDateTouched = false;
        this.cardholderNameControl = new FormControl('', Validators.required);
        this.fields = {
            number: {
                selector: '#card-number',
                placeholder: 'Card Number'
            },
            cvv: {
                selector: '#cvv',
                placeholder: '123'
            },
            expirationDate: {
                selector: '#expiration-date',
                placeholder: '12/2019'
            }
        };
        this.nmiFields = {
            ccnumber: {
                selector: '#ccnumber',
                placeholder: 'Card Number',
            },
            cvv: {
                selector: '#cvv',
                placeholder: '123',
            },
            ccexp: {
                selector: '#ccexp',
                placeholder: '12/2019',
            }
        };
        this.styles = {
            'input': {
                'font-size': '14px',
                'font-family': '"Omnes Regular", Helvetica, sans-serif',
                'font-weight': '300',
                'margin-top': '5px',
                'border-color': 'transparent'
            },
            'input::placeholder': {
                'color': '#718096',
            },
            '.input-error::placeholder': {
                'color': 'red'
            }
        };
        this.close = new EventEmitter();
        this.updated = new EventEmitter();
    }
    ngOnInit() {
        if (this.paymentProcessor === 'nmi') {
            this.showErrorMessages = true;
            this.ccId = 'ccnumber';
            this.ccExpDateId = 'ccexp';
            this.cvvId = 'cvv';
        }
        else {
            this.ccId = 'card-number';
            this.ccExpDateId = 'expiration-date';
            this.cvvId = 'cvv';
        }
    }
    ngAfterViewInit() {
        this.submitBtn = document.getElementById('update-btn');
    }
    onAddPaymentMethod() {
        this.showNewPaymentMethod = true;
        this.selectedPaymentMethod = undefined;
        this.initializeCreditCardForm();
    }
    onEditPaymentMethod(paymentMethod) {
        this.showNewPaymentMethod = false;
        this.selectedPaymentMethod = paymentMethod;
        this.initializeCreditCardForm();
    }
    markAsDefaultPaymentMethod(paymentMethod) {
        this.customerService
            .defaultPaymentMethod({ customerId: this.customer.id, paymentMethodId: paymentMethod.id })
            .subscribe((response) => {
            this.paymentMethods = response.paymentMethods;
            this.detectChanges();
        }, (error) => {
            this.alertService.danger({
                alertsCode: 'dashboard-alerts',
                title: `Can't mark this payment method as default`,
                message: error.error && error.error.message || error.message,
                timeout: 5000
            });
        });
    }
    initializeCreditCardForm() {
        if (this.paymentProcessor === 'nmi') {
            setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                yield this.loadScript();
                yield this.mountCard();
            }), 0);
        }
        else {
            this.initializeBraintreeClientSDK();
        }
    }
    onDeletePaymentMethod(paymentMethod) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.customerService
                    .deletePaymentMethod({ customerId: this.customer.id, paymentMethodId: paymentMethod.id })
                    .subscribe((response) => {
                    this.paymentMethods = response.paymentMethods;
                    this.detectChanges();
                }, (error) => {
                    this.alertService.danger({
                        alertsCode: 'dashboard-alerts',
                        title: `Can't delete payment method`,
                        message: error.error && error.error.message || error.message,
                        timeout: 5000
                    });
                });
            }
        });
    }
    addNewPaymentMethod() {
        if (this.paymentProcessor === 'nmi') {
            if (!this.isFormValid || this.cardholderNameControl.invalid) {
                return;
            }
            else {
                this.isClientInitialized = false;
                this.detectChanges();
            }
        }
        else {
            if (!this.isFormValid || this.cardholderNameControl.invalid) {
                this.showErrorMessages = true;
                this.checkAndAddInputErrorClass();
                return;
            }
            this.isClientInitialized = false;
            this.hostedFields.tokenize()
                .then(payload => {
                this.showErrorMessages = false;
                this.showTokenizeError = false;
                return this.customerService
                    .addPaymentMethod({
                    customerId: this.customer.id,
                    nonce: payload.nonce,
                    cardholderName: this.cardholderNameControl.value
                })
                    .toPromise()
                    .then((response) => {
                    this.paymentMethods = response.paymentMethods;
                    this.detectChanges();
                    this.cancelEditing();
                    this.isClientInitialized = false;
                }, (error) => {
                    console.log(error);
                    this.alertService.danger({
                        alertsCode: 'dashboard-alerts',
                        title: `Can't add the new payment method`,
                        message: error.error && error.error.message || error.message,
                        timeout: 5000
                    });
                });
            })
                .catch(err => {
                this.showErrorMessages = true;
                this.showTokenizeError = true;
                this.checkAndAddInputErrorClass();
            });
        }
    }
    updatePaymentMethod() {
        if (this.paymentProcessor === 'nmi') {
            if (!this.isFormValid || this.cardholderNameControl.invalid) {
                return;
            }
            else {
                this.isClientInitialized = false;
                this.detectChanges();
            }
        }
        else {
            if (!this.isFormValid || this.cardholderNameControl.invalid) {
                this.showErrorMessages = true;
                this.checkAndAddInputErrorClass();
                return;
            }
            this.isClientInitialized = false;
            this.hostedFields.tokenize()
                .then(payload => {
                this.showErrorMessages = false;
                this.showTokenizeError = false;
                return this.customerService
                    .updatePaymentMethod({
                    customerId: this.customer.id,
                    nonce: payload.nonce,
                    paymentMethodId: this.selectedPaymentMethod.id,
                    cardholderName: this.cardholderNameControl.value
                })
                    .toPromise()
                    .then(() => {
                    this.cancelEditing();
                    this.closeModal();
                    this.isClientInitialized = true;
                });
            })
                .catch(err => {
                this.showErrorMessages = true;
                this.showTokenizeError = true;
                this.checkAndAddInputErrorClass();
            });
        }
    }
    /**
     * Initialize the Braintree Client sdk.
     * This methods needs to be called every time the
     * payment form is in edit mode (is not saved) in order
     * to re-initialize the client sdk with the new data.
     *
     * @memberof SecurePaymentFormComponent
     */
    initializeBraintreeClientSDK() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isClientInitialized = false;
            const clientToken = yield this.braintreeService.getClientAuthorizationToken();
            client
                .create({ authorization: clientToken })
                .then((clientInstance) => hostedFields.create({
                client: clientInstance,
                fields: this.fields,
                styles: this.styles
            }))
                .then(hostedFieldsInstance => {
                this.isClientInitialized = true;
                this.hostedFields = hostedFieldsInstance;
                this.hostedFields.on('validityChange', e => {
                    const state = this.hostedFields.getState();
                    const formValid = Object
                        .keys(state.fields)
                        .every((key) => {
                        this.isCvvValid = state.fields.cvv.isValid;
                        this.isNumberValid = state.fields.number.isValid;
                        this.isExpDateValid = state.fields.expirationDate.isValid;
                        return state.fields[key].isValid;
                    });
                    this.isFormValid = formValid;
                });
                this.hostedFields.on('focus', e => {
                    switch (e.emittedBy) {
                        case 'number': {
                            this.isNumberTouched = e.fields.number.isFocused ? true : this.isNumberTouched;
                            break;
                        }
                        case 'cvv': {
                            this.isCvvTouched = e.fields.cvv.isFocused ? true : this.isCvvTouched;
                            break;
                        }
                        case 'expirationDate': {
                            this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : this.isExpDateTouched;
                            break;
                        }
                    }
                });
            })
                .catch(e => console.log(e));
        });
    }
    checkAndAddInputErrorClass() {
        if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
            this.hostedFields.clear('number');
            this.hostedFields.addClass('number', 'input-error');
        }
        if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
            this.hostedFields.clear('cvv');
            this.hostedFields.addClass('cvv', 'input-error');
        }
        if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
            this.hostedFields.clear('expirationDate');
            this.hostedFields.addClass('expirationDate', 'input-error');
        }
    }
    cancelEditing() {
        this.selectedPaymentMethod = null;
        this.isClientInitialized = null;
        this.showNewPaymentMethod = null;
        this.detectChanges();
    }
    closeModal() {
        this.close.emit();
    }
    updatePaymentMethodViaNMI(paymentToken) {
        this.isClientInitialized = false;
        this.detectChanges();
        return this.customerService
            .updatePaymentMethod({
            customerId: this.customer.id,
            nonce: paymentToken.token,
            paymentMethodId: this.selectedPaymentMethod.id,
            cardholderName: this.cardholderNameControl.value,
            paymentProcessor: 'nmi'
        })
            .toPromise()
            .then(() => {
            this.isClientInitialized = false;
            this.cancelEditing();
            this.detectChanges();
            this.closeBtn.nativeElement.click();
        });
    }
    loadScript() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isClientInitialized = true;
            const script = document.createElement('script');
            script.setAttribute('data-tokenization-key', environment.nmiClientToken);
            script.setAttribute('data-variant', 'inline');
            script.src = 'https://secure.nmi.com/token/Collect.js';
            script.type = 'text/javascript';
            return new Promise((resolve, reject) => {
                script.onload = () => {
                    resolve();
                };
                script.onerror = (error) => {
                    reject(error);
                };
                document.getElementsByTagName('body')[0].appendChild(script);
            });
        });
    }
    mountCard() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            window['CollectJS'].configure({
                'fieldsAvailableCallback': () => {
                    this.isClientInitialized = true;
                    this.detectChanges();
                },
                'validationCallback': (field, status, message) => {
                    this.validateNMIHostedFields(field, status);
                    this.detectChanges();
                },
                'timeoutCallback': () => {
                },
                'callback': (response) => {
                    this.updatePaymentMethodViaNMI(response);
                },
                variant: 'inline',
                customCss: Object.assign({}, this.styles.input, { 'background-color': '#F8F8F4' }),
                invalidCss: Object.assign({}, this.styles['.input-error::placeholder']),
                placeholderCss: Object.assign({}, this.styles['.input::placeholder']),
                fields: this.nmiFields
            });
        });
    }
    validateNMIHostedFields(field, status) {
        switch (field) {
            case 'ccnumber':
                this.isNumberValid = status;
                this.isNumberTouched = true;
                break;
            case 'cvv':
                this.isCvvValid = status;
                this.isCvvTouched = true;
                break;
            case 'ccexp':
                this.isExpDateValid = status;
                this.isExpDateTouched = true;
                break;
            default:
                console.log(`${field} not supported`, { field, status });
                break;
        }
        this.isFormValid = this.isNumberValid && this.isCvvValid && this.isExpDateValid;
        this.showErrorMessages = true;
    }
    detectChanges() {
        if (this.changeDetector && !this.changeDetector.destroyed) {
            this.changeDetector.detectChanges();
        }
    }
}
