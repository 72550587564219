import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromAdminActions from './../actions/admin.actions';
// models
// services
import { AuthService } from '@app/services';
export class AuthEffects {
    constructor(router, route, authService, actions$, store) {
        this.router = router;
        this.route = route;
        this.authService = authService;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(fromAdminActions.LOAD))
            .pipe(switchMap(() => {
            return this.authService.profile()
                .pipe(map((data) => {
                this.store.dispatch(new fromAdminActions.LoadCompleteAction(data.user));
            }), catchError(reason => {
                this.store.dispatch(new fromAdminActions.LoadFailAction(reason.errors));
                this.authService.removeJWTToken();
                return of(new fromAdminActions.LoadFailAction(reason.errors));
            }));
        }));
        this.login$ = this.actions$
            .pipe(ofType(fromAdminActions.LOGIN))
            .pipe(map((action) => action.payload), switchMap(auth => this.authService.loginUser(auth)
            .pipe(map((event) => {
            this.store.dispatch(new fromAdminActions.LoginCompleteAction(event.body.user));
            const { redirectUrl } = this.route.snapshot.queryParams;
            if (redirectUrl) {
                this.router.navigate([redirectUrl], {
                    queryParams: Object.assign({}, this.route.snapshot.queryParams, { redirectUrl: undefined })
                });
            }
        }), catchError(reason => {
            this.store.dispatch(new fromAdminActions.LoginFailAction(reason.error));
            return of(new fromAdminActions.LoginFailAction(reason.error));
        }))));
        this.logout$ = this.actions$
            .pipe(ofType(fromAdminActions.LOGOUT))
            .pipe(map(() => {
            this.store.dispatch(new fromAdminActions.LogoutCompleteAction());
            this.authService.logout();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "load$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "login$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AuthEffects.prototype, "logout$", void 0);
