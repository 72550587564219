import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map, pluck } from 'rxjs/operators';
import { get } from 'lodash'
@Injectable({
  providedIn: 'root'
})
export class ManifestsService {

  private apiUrl = environment.apiUrl + '/api';
  private apiV2Url = environment.apiUrl + '/api-v2';

  constructor(
    private http: HttpClient
  ) { }

  public getTodaysManifests() {
    console.log('ManifestsService::getTodaysManifests');
    return this.http.get(`${this.apiV2Url}/manifests`)
      .pipe(map((response: any) => get(response, 'data', [])));
  }

  public generateManifest() {
    console.log('ManifestsService::generateManifest');
    return this.http.post(`${this.apiV2Url}/manifests`, {})
      .pipe(map((response: any) => response.data));
  }
}

export interface IManifest {
  createdAt: string;
  downloadLink: string;
}
