<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 style="margin: 1rem 0">Forgot password</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <form class="col-sm-4" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()"
            *ngIf="!resetPasswordRequested">
        <div class="row">
          <input
            formControlName="email"
            class="col-sm-12 login-input"
            type="text"
            placeholder="Email">

          <div class="col-sm-12 text-right text-danger">
            <small class="error-text" *ngIf="displayErrors">Please use a valid email</small>
            <small class="error-text" *ngIf="serverError">{{ serverError }}</small>
          </div>

        </div>

        <div class="row">
          <button type="submit" class="col-sm-12 btn btn-submit">Send</button>
        </div>
      </form>

      <div class="col-sm-4" *ngIf="resetPasswordRequested">
        <p>
          {{ messageResponse }}
        </p>
        <button routerLink="/login" class="col-sm-12 btn btn-submit">Go to Login</button>
      </div>
    </div>
  </section>


</div>
