import { Update } from '@ngrx/entity/src/models';

import { Action } from '@ngrx/store';
import { Note } from './../../models/note.model';

export const LOAD_SUBSCRIPTION_NOTES = '[Notes] Load Subscription Notes';
export const LOAD_SUBSCRIPTION_NOTES_COMPLETE = '[Notes] Load Subscription Notes Complete';
export const LOAD_SUBSCRIPTION_NOTES_FAIL = '[Notes] Load Subscription Notes Fail';

export const CREATE_NOTE = '[Notes] Add Note';
export const CREATE_NOTE_COMPLETE = '[Notes] Add Note Complete';
export const CREATE_NOTE_FAIL = '[Notes] Add Note Fail';

export class LoadSubscriptionNotesAction implements Action {
  readonly type = LOAD_SUBSCRIPTION_NOTES;
  constructor(public payload: { userId: string, subscriptionId: string }) { }
}

export class LoadSubscriptionNotesCompleteAction implements Action {
  readonly type = LOAD_SUBSCRIPTION_NOTES_COMPLETE;
  constructor(public payload: Note[]) { }
}

export class LoadSubscriptionNoteFailAction implements Action {
  readonly type = LOAD_SUBSCRIPTION_NOTES_FAIL;
  constructor(public payload: any) { }
}

export class CreateNoteAction implements Action {
  readonly type = CREATE_NOTE;
  constructor(public payload: { userId: string, subscriptionId: string, message: string }) { }
};

export class CreateNoteCompleteAction implements Action {
  readonly type = CREATE_NOTE_COMPLETE;
  constructor(public payload: Note) { }
};

export class CreateNoteFailAction implements Action {
  readonly type = CREATE_NOTE_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadSubscriptionNotesAction
  | LoadSubscriptionNotesCompleteAction
  | LoadSubscriptionNoteFailAction
  | CreateNoteAction
  | CreateNoteCompleteAction
  | CreateNoteFailAction;
