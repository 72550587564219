import { Customer } from '@app/models/customer.model';
import { Subscription } from '@app/models/subscription.model';
import { Address } from '@app/models/address.model';

export class Retention {
  createdAt: Date;
  value: string;
  type: string;
  address: Address;
  subscription: Subscription;
  user: Customer;
  details?: any;

  constructor(data: any) {
    this.createdAt = data.createdAt;
    this.value = data.value;
    this.type = data.type;
    this.address = new Address(data.address);
    this.subscription = new Subscription(data.subscription);
    this.user = new Customer(data.user);
    this.details = data.details;
  }
}
