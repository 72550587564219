/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bar-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bar-alert-item/bar-alert-item.component.ngfactory";
import * as i3 from "./bar-alert-item/bar-alert-item.component";
import * as i4 from "@angular/common";
import * as i5 from "./bar-alert.component";
import * as i6 from "../alert.service";
var styles_BarAlertComponent = [i0.styles];
var RenderType_BarAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarAlertComponent, data: {} });
export { RenderType_BarAlertComponent as RenderType_BarAlertComponent };
function View_BarAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bar-alert-item", [], null, null, null, i2.View_BarAlertItemComponent_0, i2.RenderType_BarAlertItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.BarAlertItemComponent, [i1.ElementRef], { alertId: [0, "alertId"], title: [1, "title"], message: [2, "message"], color: [3, "color"], onClose: [4, "onClose"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.id; var currVal_1 = _v.context.$implicit.title; var currVal_2 = _v.context.$implicit.message; var currVal_3 = _v.context.$implicit.type; var currVal_4 = _co.onCloseAlert.bind(_co); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BarAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarAlertComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BarAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bar-alert", [], null, null, null, View_BarAlertComponent_0, RenderType_BarAlertComponent)), i1.ɵdid(1, 114688, null, 0, i5.BarAlertComponent, [i1.ElementRef, i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarAlertComponentNgFactory = i1.ɵccf("app-bar-alert", i5.BarAlertComponent, View_BarAlertComponent_Host_0, { alertsCode: "alerts-code", xAling: "x-align", yAling: "y-align" }, {}, []);
export { BarAlertComponentNgFactory as BarAlertComponentNgFactory };
