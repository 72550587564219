import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@app/services';
// services
// actions
// selectors

@Injectable()
export class RecoveryPasswordGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const recoveryToken = route.queryParams.recoveryToken;
    const authToken = this.authService.token;

    if (authToken || !recoveryToken) {
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
      return false;
    }

    return true;
  }
}
