/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./builds-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/components/modal/modal.component.ngfactory";
import * as i4 from "../../shared/components/modal/modal.component";
import * as i5 from "./builds-list.component";
import * as i6 from "./services/builds.service";
var styles_BuildsListComponent = [i0.styles];
var RenderType_BuildsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuildsListComponent, data: {} });
export { RenderType_BuildsListComponent as RenderType_BuildsListComponent };
function View_BuildsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], null, null); }
function View_BuildsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [["class", "table-row__item--hide pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2)], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.fileName); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.downloadLink); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.date), "short")); _ck(_v, 7, 0, currVal_2); }); }
function View_BuildsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download link"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Created At"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BuildsListComponent_3)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.builds$)); _ck(_v, 12, 0, currVal_0); }, null); }
export function View_BuildsListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "trigger pointer button-animation"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showModal$.next(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Builds list\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "app-modal", [["primaryBtn", "Close"], ["title", "Today's builds"]], null, [[null, "atCloseBtn"], [null, "atPrimaryBtn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("atCloseBtn" === en)) {
        var pd_0 = (_co.showModal$.next(false) !== false);
        ad = (pd_0 && ad);
    } if (("atPrimaryBtn" === en)) {
        var pd_1 = (_co.showModal$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i1.ɵdid(4, 114688, null, 0, i4.ModalComponent, [], { show: [0, "show"], title: [1, "title"], primaryBtn: [2, "primaryBtn"] }, { atPrimaryBtn: "atPrimaryBtn", atCloseBtn: "atCloseBtn" }), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_BuildsListComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_BuildsListComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.showModal$)); var currVal_1 = "Today's builds"; var currVal_2 = "Close"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.loadingBuilds$)); _ck(_v, 7, 0, currVal_3); var currVal_4 = !i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.loadingBuilds$)); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_BuildsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-builds-list", [], null, null, null, View_BuildsListComponent_0, RenderType_BuildsListComponent)), i1.ɵdid(1, 180224, null, 0, i5.BuildsListComponent, [i6.BuildsService], null, null)], null, null); }
var BuildsListComponentNgFactory = i1.ɵccf("app-builds-list", i5.BuildsListComponent, View_BuildsListComponent_Host_0, {}, {}, []);
export { BuildsListComponentNgFactory as BuildsListComponentNgFactory };
