<form (submit)="onSubmit()">
  <div class="input-group">
    <input type="text"
           [formControl]="searchControl"
           placeholder="Please write full email or full name."
           class="form-control"/>
  </div>
</form>
<div class="results" *ngIf="displayResults">
  <ul class="list-group list-group-flush">

    <li class="list-group-item" *ngIf="autoCompleteResult?.subscriptions.length">
      Subscriptions
      <ul class="list-group list-group-flush results-items">
        <li class="list-group-item" *ngFor="let subscription of autoCompleteResult.subscriptions"
            (click)="selectSubscription(subscription)">
          <div>
            <!--{{ subscription.id }}-->
            {{ subscription._id?.firstName + ' ' + subscription._id?.lastName }}
          </div>
        </li>
      </ul>
    </li>
    <li class="list-group-item" *ngIf="autoCompleteResult?.customers.length">
      Customers
      <ul class="list-group list-group-flush results-items">
        <li class="list-group-item" *ngFor="let customer of autoCompleteResult.customers"
            (click)="selectCustomer(customer)">
          <div>
            Name: {{ customer.firstName + ' ' + customer.lastName }}
          </div>
          <div>Email: {{ customer.email }}</div>
        </li>
      </ul>
    </li>
    <li class="list-group-item" *ngIf="autoCompleteResult.shipments.length">
      Shipments
      <ul class="list-group list-group-flush results-items">
        <li class="list-group-item" *ngFor="let shipment of autoCompleteResult.shipments;"
            (click)="selectShipment(shipment)">
          <div>
            <!--<span>Subscription: {{ shipment.subscriptionId }}</span>-->
            <!--<span>Receiver: {{ shipment.receiver }}</span>-->
            <span>Receiver: {{ shipment._id }}</span>
          </div>
          <!--<div>TrackingCode: {{ shipment.tracker?.trackingCode }}</div>-->
        </li>
      </ul>
    </li>
    <li class="list-group-item" *ngIf="autoCompleteResult?.addresses.length">
      Address
      <ul class="list-group list-group-flush results-items">
        <li class="list-group-item" *ngFor="let address of autoCompleteResult.addresses"
            (click)="selectAddress(address)">
          <span> {{ address._id }}</span>
        </li>
      </ul>
    </li>
  </ul>
</div>
