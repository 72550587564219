import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { BuildsService, IBuild } from './services/builds.service';
import { tap, filter, takeUntil, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-builds-list',
  templateUrl: './builds-list.component.html',
  styleUrls: ['./builds-list.component.scss']
})
export class BuildsListComponent implements OnDestroy {
  showModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingBuilds$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  builds$: BehaviorSubject<IBuild[]> = new BehaviorSubject<IBuild[]>([]);

  componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  showModalSubscription: Subscription = this.showModal$
    .pipe(tap(value => this.loadingBuilds$.next(value)))
    .pipe(filter(data => !!data))
    .pipe(switchMap(_ => this.buildsService.getTodaysBuilds()))
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(builds => {
      this.builds$.next(builds);
      this.loadingBuilds$.next(false);
    });

  constructor(
    private buildsService: BuildsService
  ) { }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
  }
}
