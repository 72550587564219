<div class="row">
  <section class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h2 style="margin: 1rem 0">Reset Password</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <form class="col-sm-4" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!passwordChangedMessage">
        <div class="form-group">
          <div class="row">
            <input
              formControlName="password"
              class="col-sm-9 login-input"
              [type]="showPassword ? 'text' : 'password'"
              placeholder="Password">
            <button type="button" (click)="showPassword = !showPassword" class="col-sm-3 btn-show">
              {{showPassword ? 'HIDE' : 'SHOW'}}
            </button>
            <div class="col-sm-12 text-danger text-right" *ngIf="displayErrors">
              <small *ngIf="form.get('password').errors?.minlength">Please write a password bigger</small>
              <small *ngIf="form.get('password').errors?.required">Please write your new password</small>
            </div>
          </div>

        </div>
        <div class="form-group">
          <div class="row">
            <input
              formControlName="confirmPassword"
              class="col-sm-9 login-input"
              [type]="showConfirmPassword ? 'text' : 'password'"
              placeholder="Confirm Password">
            <button type="button" (click)="showConfirmPassword = !showConfirmPassword" class="col-sm-3 btn-show">
              {{showConfirmPassword ? 'HIDE' : 'SHOW'}}
            </button>

            <div class="col-sm-12 text-danger text-right" *ngIf="displayErrors">
              <small *ngIf="form.get('password').errors?.minlength">Please write a password bigger</small>
              <small *ngIf="form.get('password').errors?.required">Please confirm your new password</small>
              <small *ngIf="!form.get('password').errors && !arePasswordsEquals">Passwords don't match</small>
            </div>
          </div>
        </div>

        <div class="row text-danger" *ngIf="error">
          <span>{{ error }}</span>
        </div>

        <div class="row">
          <button type="submit" class="col-sm-12 btn btn-submit">Send</button>
        </div>
      </form>

      <div class="col-sm-4" *ngIf="passwordChangedMessage">
        <p>{{ passwordChangedMessage || 'Your password has been changed'}}</p>
        <button routerLink="/login" type="button" class="col-sm-12 btn btn-submit">Go to Login</button>
      </div>
    </div>
  </section>
</div>
