import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


const COMPONENTS = [];

// Services
import * as fromServices from './services';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: COMPONENTS,
  providers: [...fromServices.services],
  exports: COMPONENTS
})
export class CoreModule { }
