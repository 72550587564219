import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { map } from 'rxjs/operators';

// config
import { environment } from '@env/environment';

@Injectable()
export class AppService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getUUID() {
    return this.httpClient
      .get(`${ this.apiUrl }/client-uuid`)
      .pipe(map((response: any) => response.uuid));
  }
}
