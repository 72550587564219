import { Route } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { RetentionComponent } from './pages/retention/retention.component';
import { AuthGuard } from '@app/guards/auth/auth.guard';
const ɵ0 = () => import("./../public-reports/public-reports.module.ngfactory").then(m => m.PublicReportsModuleNgFactory);
const routes = [
    {
        component: DashboardComponent, path: '', pathMatch: 'full', canActivate: [AuthGuard],
    },
    {
        path: 'stats',
        children: [
            {
                path: 'retention', component: RetentionComponent, canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: '',
        loadChildren: ɵ0
    }
];
export class DashboardRoutingModule {
}
export { ɵ0 };
