import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/services';


@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent implements OnInit {
  @Input() customer: Customer;
  @Output() updated: EventEmitter<{ updatedUser: Customer, updatedReferral: Customer}> = new EventEmitter<{ updatedUser: Customer, updatedReferral: Customer}>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  email: string
  error: { success: boolean, message: string }
  constructor(private customerService: CustomerService) {

  }

  ngOnInit(): void {}

  addReferral() {
    this.customerService.addReferrals(this.customer.id, this.email).subscribe((data) => {
      this.updated.emit(data);
      this.error = null;
    }, ({error}) => {
      console.log(error);
      this.error = error;
    });
  }

  closeModal() {
    this.close.emit();
    this.error = null;
  }
}
