import { Admin } from './../../models/admin.model';
import * as adminActions from './../actions/admin.actions';

export interface State {
  data: Admin;
  loaded: boolean;
  loading: boolean;
  isAuthenticated: boolean;
  error: {
    message: string;
    status: number
  };
}

export const initialState: State = {
  data: null,
  loaded: true,
  loading: false,
  isAuthenticated: false,
  error: null
};

export function reducer(
  state: State = initialState,
  action: adminActions.Actions
): State {
  switch (action.type) {

    case adminActions.LOGIN_COMPLETE:
    case adminActions.LOAD_COMPLETE: {
      const data = { ...state.data, ...action.payload };
      return { ...state, isAuthenticated: true, data, loaded: true, loading: false, error: null };
    }

    case adminActions.LOGOUT_COMPLETE: {
      return { ...initialState, error: null };
    }

    case adminActions.LOGIN_FAIL:
    case adminActions.LOAD_FAIL: {
      return { ...state, error: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getAdminData = (state: State) => state.data;
export const getAdminLoaded = (state: State) => state.loaded;
export const getAdminLoading = (state: State) => state.loading;
export const getAdminIsAuthenticated = (state: State) => state.isAuthenticated;
export const getAdminError = (state: State) => state.error;
