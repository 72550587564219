import { createEntityAdapter } from '@ngrx/entity';
import * as notesActions from './../../store/actions/notes.actions';
const ɵ0 = (note) => note.id;
export const adapter = createEntityAdapter({
    selectId: ɵ0,
    sortComparer: false
});
export const initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
});
export function reducer(state = initialState, action) {
    switch (action.type) {
        case notesActions.LOAD_SUBSCRIPTION_NOTES:
        case notesActions.CREATE_NOTE: {
            return Object.assign({}, state, { loading: true, loaded: false });
        }
        case notesActions.LOAD_SUBSCRIPTION_NOTES_COMPLETE: {
            return adapter.addAll(action.payload, Object.assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case notesActions.CREATE_NOTE_COMPLETE: {
            return adapter.addOne(action.payload, Object.assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case notesActions.LOAD_SUBSCRIPTION_NOTES_FAIL:
        case notesActions.CREATE_NOTE_FAIL: {
            return Object.assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export const getNotes = (state) => state.entities;
export const getNotesLoaded = (state) => state.loaded;
export const getNotesLoading = (state) => state.loading;
export const getNotesError = (state) => state.error;
export { ɵ0 };
