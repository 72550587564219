import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Retention } from '@app/dashboard/models/retentions.model';


@Injectable()
export class StatsService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient
  ) { }

  public fetchLatestRetentions(): Observable<Retention[]> {
    const values = ['TURN-ON_SUBSCRIPTION', 'TURN-OFF_SUBSCRIPTION', 'CANCEL_SUBSCRIPTION'];
    let params = new HttpParams().set('limit', '15');
    params = params.append('sort', '-1');
    values.forEach((value, i) => {
      params = params.append(`values[${i}]`, value);
    });

    return this.http.get<{ retentions: any }>(`${this.apiUrl}/stats/retentions/`, { params })
      .pipe(map(data => data.retentions.map(r => new Retention(r))));
  }

  public fetchYesterdayDailyStats() {
    return this.http.get<{ stats: any }>(`${this.apiUrl}/stats/daily/yesterday-stats/`)
      .pipe(map(data => data.stats));
  }
}
