export class Action {
  id: string;
  type: string;
  userId: string;
  subscriptionId?: number;
  value: string;
  createdAtPt: string;
  details?: any;
  createdAt: Date;
  updatedAt: Date;

  constructor(data) {
    this.id = data._id;
    this.type = data.type;
    this.userId = data.user;
    this.subscriptionId = data.subscription;
    this.value = data.value;
    this.createdAtPt = data.createdAtPt;
    this.details = data.details;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
